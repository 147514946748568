// libraries
import { useMutation } from 'react-query';

// misc
import { useErrorHandler } from '@hooks/useErrorHandler';
import request from '@utils/request';
import { ServerResponse } from './types';
import { useUser } from '@context/UserProvider';

export type ToggleFollowResponse = [];

export interface ToggleFollowRequest {
  following_id: number;
  operation: 'follow' | 'unfollow';
}

export const useToggleFollow = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: ToggleFollowResponse) => void;
  onError?: (res: ServerResponse<unknown, ToggleFollowRequest>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();
  const { user } = useUser();

  // request
  const query = useMutation<
    ToggleFollowResponse,
    ServerResponse<unknown, ToggleFollowRequest> | undefined,
    ToggleFollowRequest
  >(
    [`update-follower`, user?.id],
    async (requestData: ToggleFollowRequest) => {
      const data = await request<ServerResponse<ToggleFollowResponse, ToggleFollowRequest>>({
        method: 'post',
        url: `/update-follower/${user?.id}`,
        data: requestData,
      });

      return data.data;
    },
    {
      onError: error => {
        triggerError({ error });
        onError && onError(error);
      },
      onSuccess: onSuccess,
    },
  );

  // return
  return query;
};
