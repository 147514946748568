// libraries
import React, { ReactNode } from 'react';
import Modal, { ModalProps } from 'react-native-modal';
import styled from 'styled-components/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ViewStyle } from 'react-native';

// components
import { CustomIcon } from '@components/atoms';

// style
import { genericStyles } from '@styles/genericStyles';

export interface CustomModalProps {
  isVisible: boolean;
  children: ReactNode;
  onClose: () => void;
  isDarkBackground?: boolean;
  hideClose?: boolean;
  headerContainerStyle?: ViewStyle;
  renderHeader?: () => ReactNode;
  animationIn?: ModalProps['animationIn'];
  animationOut?: ModalProps['animationOut'];
}

export const CustomModal = ({
  isVisible,
  children,
  onClose,
  isDarkBackground = false,
  renderHeader,
  headerContainerStyle,
  animationIn,
  animationOut,
}: CustomModalProps) => {
  // variables
  const { top } = useSafeAreaInsets();

  // renders
  return (
    <Modal
      style={genericStyles.m0}
      isVisible={isVisible}
      onBackdropPress={onClose}
      animationIn={animationIn || 'fadeIn'}
      animationOut={animationOut || 'fadeOut'}
      hideModalContentWhileAnimating
      backdropTransitionOutTiming={0}
      onBackButtonPress={onClose}
      backdropOpacity={1}>
      <Container>
        <HeaderContainer top={top} onPress={onClose} style={headerContainerStyle}>
          {renderHeader !== undefined ? (
            renderHeader()
          ) : (
            <CustomIcon name="x" color={isDarkBackground ? 'white' : 'primary'} />
          )}
        </HeaderContainer>
        {children}
      </Container>
    </Modal>
  );
};

const Container = styled.View({
  ...genericStyles.fill,
});

const HeaderContainer = styled.Pressable<{ top: number }>(({ theme: { layout }, top }) => ({
  ...genericStyles.positionAbsolute,
  top: top + layout.padding * 0.5,
  right: layout.contentPadding / 2,
  zIndex: 2,
}));
