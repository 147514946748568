// libraries
import React from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Linking, Pressable } from 'react-native';

// components
import { CustomText } from '@components/atoms';
import { Checkbox } from '@components/molecules';

// styles
import { genericStyles } from '@styles/genericStyles';

interface TermsAndConditionCheckboxProps {
  control: Control<any>;
}

export const TermsAndConditionCheckbox: React.FC<TermsAndConditionCheckboxProps> = ({
  control,
}) => {
  // variables
  const { t } = useTranslation();

  // renders
  return (
    <Checkbox
      containerStyle={genericStyles.selfCenter}
      control={control}
      name="agreeCheck"
      text={
        <CustomText size={10}>
          {t('common.iAgreeWith')}
          <Pressable onPress={() => Linking.openURL('https://www.crowlr.com/en/privacy-policy/')}>
            <CustomText color="primary" size={10}>
              {' '}
              {t('common.privacyPolicy')}{' '}
            </CustomText>
          </Pressable>
          {t('common.and')}
          <Pressable onPress={() => Linking.openURL('https://www.crowlr.com/en/terms-of-service/')}>
            <CustomText color="primary" size={10}>
              {' '}
              {t('common.termsAndCondition')}{' '}
            </CustomText>
          </Pressable>
        </CustomText>
      }
      defaultValue={false}
      rules={{ required: true }}
      errorStyle={{ fontSize: 10, textAlign: 'center' }}
    />
  );
};
