// libraries
import { useMutation } from 'react-query';

// misc
import { useErrorHandler } from '@hooks/useErrorHandler';
import request from '@utils/request';
import { ServerResponse } from './types';

export type DeletePinResponse = unknown;

export interface DeletePinRequest {
  pinId: number;
}

export const useDeletePin = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: DeletePinResponse) => void;
  onError?: (res: ServerResponse<unknown, DeletePinRequest>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();

  // request
  const query = useMutation<
    DeletePinResponse,
    ServerResponse<unknown, DeletePinRequest> | undefined,
    DeletePinRequest
  >(
    [`deletePin`],
    async ({ pinId }: DeletePinRequest) => {
      const data = await request<ServerResponse<DeletePinResponse, DeletePinRequest>>({
        method: 'delete',
        url: `/pins/${pinId}`,
      });

      return data.data;
    },
    {
      onError: error => {
        triggerError({ error });
        onError && onError(error);
      },
      onSuccess: onSuccess,
    },
  );

  // return
  return query;
};
