// libraries
import { useMutation } from 'react-query';

// misc
import { useErrorHandler } from '@hooks/useErrorHandler';
import request from '@utils/request';
import { ServerResponse, User } from './types';
import { useUser } from '@context/UserProvider';

export type UpdateUserResponse = Pick<
  User,
  'first_name' | 'last_name' | 'email' | 'language' | 'image'
>;

export type UpdateUserRequest = Pick<
  User,
  'first_name' | 'last_name' | 'email' | 'language' | 'image'
>;

export const useUpdateUser = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: UpdateUserResponse) => void;
  onError?: (res: ServerResponse<unknown, UpdateUserRequest>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();
  const { user, getAuthUser } = useUser();

  // request
  const query = useMutation<
    UpdateUserResponse,
    ServerResponse<unknown, UpdateUserRequest> | undefined,
    UpdateUserRequest
  >(
    [`users`, user?.id],
    async (requestData: UpdateUserRequest) => {
      const data = await request<ServerResponse<UpdateUserResponse, UpdateUserRequest>>({
        method: 'put',
        url: `/users/${user?.id}`,
        data: requestData,
      });
      await getAuthUser();
      return data.data;
    },
    {
      onError: error => {
        triggerError({ error });
        onError && onError(error);
      },
      onSuccess: onSuccess,
    },
  );

  // return
  return query;
};
