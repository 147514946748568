import { CategoryType, SubCategory } from '@api/types';
import {
  LinkingOptions,
  NavigatorScreenParams,
  RouteProp,
  useNavigation,
} from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React from 'react';

export type RouteItem<
  T =
    | RootStackParamList
    | AuthStackParamList
    | HomeStackParamList
    | ProfileStackParamList
    | DrawerParamList,
> = {
  name: keyof T;
  component: React.ComponentType;
  header?: () => React.ReactNode;
  forRole?: 'only-loggedin-user' | 'only-guest' | 'everyone';
  title?: string;
};

export type AuthStackParamList = {
  signIn: undefined;
  signUp: undefined;
  forgotPassword: undefined;
};

export type HomeStackParamList = {
  pinList: undefined;
  addressSelection: {
    mainCategory: CategoryType;
    subCategory: SubCategory;
    location: { lat: number; lng: number };
  };
};

export type ProfileStackParamList = {
  profile: { id: number };
  editProfile: undefined;
  followers: {
    id: number;
  };
  following: {
    id: number;
  };
  userPins: {
    id: number;
  };
};

export type DrawerParamList = {
  home: NavigatorScreenParams<HomeStackParamList>;
  onboarding: undefined;
  changePassword: undefined;
  myProfile: undefined; //fake one
  authDrawer: undefined; //fake one
  shareApp: undefined; //fake one
};

export type RootStackParamList = {
  drawer: NavigatorScreenParams<DrawerParamList>;
  auth: NavigatorScreenParams<AuthStackParamList>;
  profile: NavigatorScreenParams<ProfileStackParamList>;
};

export type AppNavigationProp = NativeStackNavigationProp<RootStackParamList>;

export type ScreenFC<T extends keyof RootStackParamList> = React.FC<{
  navigation: NativeStackNavigationProp<RootStackParamList, T>;
  route: RouteProp<RootStackParamList, T>;
}>;

export const useAppNavigation = () => useNavigation<AppNavigationProp>();

export const linking: LinkingOptions<RootStackParamList> = {
  config: {
    screens: {
      drawer: {
        path: '',
        screens: {
          home: {
            path: '',
            screens: {
              pinList: 'home',
              addressSelection: 'address-selection',
            },
          },
          changePassword: 'change-password',
        },
      },
      auth: {
        path: 'auth',
        screens: {
          signIn: 'sign-in',
          signUp: 'sign-up',
        },
      },
      profile: {
        path: 'profile',
        screens: {
          editProfile: 'edit',
          followers: 'followers/:id',
          following: 'following/:id',
          userPins: 'pins/:id',
          profile: 'user/:id',
        },
      },
    },
  },
  prefixes: [],
};
