// libraries
import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { CompositeNavigationProp, RouteProp } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { FlatList } from 'react-native';
import { useTranslation } from 'react-i18next';

// components
import { EmptyList, FullScreenLoader, ProfileItem } from '@components/molecules';
import { CustomText, Loading, SpacerColumn } from '@components/atoms';

// misc
import { ProfileStackParamList, RootStackParamList } from '@utils/navigation';
import { User } from '@api/types';
import { useGetFollowers } from '@api/useGetFollowers';
import { genericStyles } from '@styles/genericStyles';

export type FollowersScreenProp = {
  navigation: CompositeNavigationProp<
    NativeStackNavigationProp<RootStackParamList, 'profile'>,
    NativeStackNavigationProp<ProfileStackParamList, 'followers'>
  >;
  route: RouteProp<ProfileStackParamList, 'followers'>;
};

export const FollowersScreen: React.FC<FollowersScreenProp> = ({ route }) => {
  // variables
  const { layout } = useTheme();
  const { t } = useTranslation();
  const { data, isLoading, isFetching } = useGetFollowers(route.params?.id || 0);
  const list: User[] = useMemo(
    () => data?.pages.reduce((pv, cv) => [...pv, ...cv.data], []),
    [data],
  );

  // returns
  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Container>
      <FlatList
        data={list}
        keyExtractor={item => item.id.toString()}
        renderItem={({ item }) => <ProfileItem user={item} />}
        ListHeaderComponent={() => <SpacerColumn size={2.5} />}
        ItemSeparatorComponent={() => <SpacerColumn size={2.5} />}
        contentContainerStyle={{
          ...genericStyles.fill,
          paddingHorizontal: layout.padding_x3,
        }}
        ListEmptyComponent={() => <EmptyList text={t('errors.emptyList')} />}
        ListFooterComponent={isFetching && (() => <Loading />)}
        style={genericStyles.fill}
      />
    </Container>
  );
};

const Container = styled.View({
  flex: 1,
});
