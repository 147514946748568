// libraries
import React from 'react';
import styled from 'styled-components/native';
import { RouteProp } from '@react-navigation/native';

// components
import { Profile } from '@components/organisms/Profile';
import { Loading } from '@components/atoms';

// misc
import { ProfileStackParamList } from '@utils/navigation';
import { useGetUser } from '@api/useGetUser';
import { ScrollView } from 'react-native';

export type AddressSelectionScreenProp = {
  route: RouteProp<ProfileStackParamList, 'profile'>;
};

export const ProfileScreen: React.FC<AddressSelectionScreenProp> = ({ route }) => {
  // variables
  const { id } = route.params;
  const { data, isLoading } = useGetUser(id);

  return (
    <Container>
      {isLoading ? (
        <Loading />
      ) : (
        <ScrollView>
          <Profile user={data} />
        </ScrollView>
      )}
    </Container>
  );
};

const Container = styled.View(({ theme: { layout, colors } }) => ({
  backgroundColor: colors.white,
  padding: layout.padding_x2,
  flex: 1,
}));
