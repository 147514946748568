// libraries
import { SheetManager as ASSheetManager } from 'react-native-actions-sheet';

// misc
import { CategorySelectionSheetProps } from './AddressSheets/CategorySelectionSheet';
import { PreviewSheetProps } from './AddressSheets/PreviewSheet';
import { ProfileSheetProps } from './ProfileSheet';

export type SheetManagerType = {
  'profile-sheet': ProfileSheetProps;
  'category-selection-sheet': CategorySelectionSheetProps;
  'preview-sheet': PreviewSheetProps;
};

export const CustomSheetManager = {
  show: <ID extends keyof SheetManagerType>(id: ID, payload?: SheetManagerType[ID]) =>
    ASSheetManager.show(id, {
      payload,
    }),
  hide: <ID extends keyof SheetManagerType>(id: ID) => ASSheetManager.hide(id),
  hideAll: () => {
    try {
      ASSheetManager.hide('profile-sheet');
      ASSheetManager.hide('category-selection-sheet');
      ASSheetManager.hide('preview-sheet');
    } catch (e) {
      console.log('e', e);
    }
  },
  ...ASSheetManager,
};
