/* eslint-disable @typescript-eslint/no-empty-function */
// libraries
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';
import { showMessage } from 'react-native-flash-message';
import { useNavigation } from '@react-navigation/native';

// components
import {
  Avatar,
  BigButton,
  CustomIcon,
  CustomPressable,
  CustomText,
  Divider,
  Loading,
  SpacerColumn,
  SpacerRow,
} from '@components/atoms';

// styles
import { genericStyles } from '@styles/genericStyles';

// misc
import { ProfileStackParamList, useAppNavigation } from '@utils/navigation';
import { User } from '@api/types';
import { useUser } from '@context/UserProvider';
import { getFullName } from '@utils/text';
import { generateInitialImage } from '@utils/image';
import { useGetCategoryList } from '@api/useGetCategoryList';
import { useGetUser } from '@api/useGetUser';
import { useToggleFollow } from '@api/useToggleFollow';
import inviteImage from '@assets/images/invite.png';
import { CustomSheetManager } from '@components/organisms/sheets/utils';
import { ShareDialog } from '@components/share/shareDialog';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { navigationRef } from '../../../App';
import { avatarData } from '@utils/constatnt';

export interface ProfileProps {
  user: User;
  onFollowChange?: (isFollowed: boolean) => void;
}

export const Profile: React.FC<ProfileProps> = ({ user, onFollowChange }) => {
  // variables
  const navigation = useAppNavigation();
  const profileNavigation = useNavigation<NativeStackNavigationProp<ProfileStackParamList>>();
  const { t } = useTranslation();
  const { user: currentUser } = useUser();
  const [isProfileScreen, setIsProfileScreen] = useState(false);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [shareToken, setShareToken] = useState('');

  useEffect(() => {
    setTimeout(() => {
      const isUnderProfile = ['followers', 'following', 'profile', 'userPins'].includes(
        navigationRef?.getCurrentRoute().name,
      );
      setIsProfileScreen(isUnderProfile);
    }, 1000);
  }, []);

  const isOwnProfile = user?.id === currentUser?.id;
  useGetCategoryList();
  const { data, isFetching } = useGetUser(user?.id || 0);
  const [isFollowed, setIsFollowed] = useState(user?.is_followed);
  const { mutate, isLoading } = useToggleFollow({
    onSuccess: () => {
      setIsFollowed(prev => !prev);
      onFollowChange && onFollowChange(!isFollowed);
    },
  });

  // hooks
  useEffect(() => {
    setIsFollowed(!!data?.is_followed);
  }, [data]);

  // functions
  const onToggleFollow = () =>
    mutate({ following_id: user?.id, operation: isFollowed ? 'unfollow' : 'follow' });

  const onPressEditProfile = () => {
    isProfileScreen
      ? profileNavigation.navigate('editProfile')
      : navigation.navigate('profile', { screen: 'editProfile' });
    CustomSheetManager.hideAll();
  };

  const onPressFollowing = () => {
    CustomSheetManager.hideAll();
    isProfileScreen
      ? profileNavigation.navigate('following', { id: user?.id })
      : navigation.navigate('profile', { screen: 'following', params: { id: user?.id } });
  };

  const onPressFollowers = () => {
    CustomSheetManager.hideAll();
    isProfileScreen
      ? profileNavigation.navigate('followers', { id: user?.id })
      : navigation.navigate('profile', {
          screen: 'followers',
          params: { id: user?.id },
          path: 'test',
        });
  };

  const onPressPin = () => {
    CustomSheetManager.hideAll();
    isProfileScreen
      ? profileNavigation.navigate('userPins', { id: data?.id })
      : navigation.navigate('profile', {
          screen: 'userPins',
          params: { id: data?.id },
        });
  };

  const shareUrl = () => {
    try {
      // CustomSheetManager.hideAll();
      if (Platform.OS === 'web') {
        Clipboard.setString(window?.location.origin + '/profile/user/' + user?.id);
        setShareToken(window?.location.origin + '/profile/user/' + user?.id);
        setOpenShareDialog(true);
        showMessage({ message: t('success.profileCopy'), type: 'success' });
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  // returns
  const Stat = useCallback(
    ({
      title,
      value,
      onPress,
      isLoading,
    }: {
      title: string;
      value: number;
      onPress: () => void;
      isLoading: boolean;
    }) => (
      <StatItem onPress={isLoading ? undefined : onPress} style={genericStyles.aiCenter}>
        {isLoading ? (
          <Loading />
        ) : (
          <CustomText font="bodyMedium" size={16}>
            {value}
          </CustomText>
        )}
        <SpacerColumn size={1.5} />
        <CustomText font="bodyRegular" size={12} color="silver">
          {title}
        </CustomText>
      </StatItem>
    ),
    [],
  );

  return (
    <Container>
      <AvatarContainer>
        <Avatar
          uri={
            user.image !== null && user.image !== '0'
              ? avatarData[user.image].icon
              : generateInitialImage(getFullName(user))
          }
          size="big"
        />
        <SpacerColumn size={2} />
        <CustomText font="heading" size={24}>
          {getFullName(user)}
        </CustomText>
      </AvatarContainer>
      <SpacerColumn size={4} />

      <StatContainer>
        <Stat
          title={t('common.pins')}
          value={data?.pins_count || 0}
          onPress={onPressPin}
          isLoading={isFetching}
        />
        <Stat
          title={t('common.followers')}
          value={data?.followers_count || 0}
          isLoading={isFetching}
          onPress={onPressFollowers}
        />
        <Stat
          title={t('common.following')}
          value={data?.followings_count || 0}
          onPress={onPressFollowing}
          isLoading={isFetching}
        />
      </StatContainer>
      <SpacerColumn size={2} />

      <View style={[genericStyles.rowJcCenter, { flexWrap: 'wrap' }]}>
        {isOwnProfile ? (
          <BigButton text={t('common.editProfile')} onPress={onPressEditProfile} color="error" />
        ) : (
          <>
            {!!user === true && currentUser && (
              <View style={{ margin: 5 }}>
                <BigButton
                  text={isFollowed ? t('common.unfollow') : t('common.follow')}
                  onPress={onToggleFollow}
                  minWidth={160}
                  outline={isFollowed}
                  color={isFollowed ? 'silver' : 'primary'}
                  isLoading={isLoading || isFetching}
                />
                <SpacerRow size={2} />
              </View>
            )}
            <View style={{ margin: 5 }}>
              <BigButton
                text={t('common.showPins')}
                outline
                onPress={onPressPin}
                color="error"
                minWidth={160}
                isLoading={isFetching}
              />
            </View>
          </>
        )}
      </View>

      <SpacerColumn size={2} />
      <Divider color="silver" opacity={0.5} />

      {shareToken && (
        <ShareDialog
          open={openShareDialog}
          onClose={() => setOpenShareDialog(false)}
          shareToken={shareToken}
        />
      )}

      <ShareProfileContainer>
        <Image source={inviteImage} />

        <CustomText textAlign="center" style={{ width: '80%' }}>
          {t('sheets.profile.sendThisLink')}
        </CustomText>

        <SpacerColumn size={1.5} />

        <BigButton
          text={t('sheets.profile.inviteSomeone')}
          leftIcon={<ShareIcon name="share-2" color="white" />}
          onPress={shareUrl}
        />
      </ShareProfileContainer>
    </Container>
  );
};

const Container = styled.View({});

const AvatarContainer = styled.View({
  alignSelf: 'center',
  alignItems: 'center',
});

const StatContainer = styled.View(({ theme: { layout } }) => ({
  flexDirection: 'row',
  paddingHorizontal: layout.padding_x2,
  justifyContent: 'space-evenly',
  maxWidth: 400,
  alignSelf: 'center',
}));

const StatItem = styled(CustomPressable)(() => ({
  width: 100,
}));

const ShareProfileContainer = styled.View(({ theme: { layout } }) => ({
  ...genericStyles.jcAiCenter,
  marginVertical: layout.padding_x1,
}));

const Image = styled.Image({
  width: 200,
  height: 200,
  marginVertical: -20,
});

const ShareIcon = styled(CustomIcon)(() => ({}));
