import { useEffect, useState } from 'react';
import * as Location from 'expo-location';
import { showMessage } from 'react-native-flash-message';
import { useTranslation } from 'react-i18next';

const center = {
  lat: -3.745,
  lng: -38.523,
};

export const useCurrentLocation = () => {
  // variables
  const [location, setLocation] = useState<Location.LocationObject>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  // hooks
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        showMessage({ message: t('errors.locationPermissionDenied'), type: 'danger' });
        setIsLoading(false);
        return;
      }

      const location = await Location.getCurrentPositionAsync({});
      setLocation(location);
      setIsLoading(false);
    })();
  }, []);

  // returns
  return {
    location: location ? { lat: location.coords.latitude, lng: location.coords.longitude } : center,
    isLoading,
  };
};
