// libraries
import React from 'react';
import styled from 'styled-components/native';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

// components
import { BigButton, Input, SpacerColumn, Logo } from '@components/atoms';

// misc
import { useAppNavigation } from '@utils/navigation';
import { ChangePasswordRequest, useChangePassword } from '@api/useChangePassword';
import { useFormError } from '@hooks/useFormError';

export const ChangePasswordScreen = () => {
  // variables
  const { t } = useTranslation();
  const navigation = useAppNavigation();
  const { control, watch, handleSubmit, setError, reset } = useForm<ChangePasswordRequest>({
    mode: 'all',
  });

  const { mutate, error, isLoading } = useChangePassword({
    onSuccess: () => {
      reset();
      showMessage({
        message: t('success.changePassword'),
        type: 'success',
        duration: 1000,
      });
      navigation.navigate('drawer', { screen: 'home' });
    },
  });
  useFormError<ChangePasswordRequest>(error, setError, reset);

  // functions
  const onPressSubmit = (data: ChangePasswordRequest) => {
    mutate(data);
  };

  // returns
  return (
    <Container>
      <Content>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <SpacerColumn size={5} />

        <Input<ChangePasswordRequest>
          control={control}
          name="old_password"
          placeHolder={t('screens.changePassword.currentPassword')}
          rules={{ required: true }}
        />
        <SpacerColumn size={2} />

        <Input<ChangePasswordRequest>
          control={control}
          name="new_password"
          placeHolder={t('screens.changePassword.newPassword')}
          rules={{ required: true }}
        />
        <SpacerColumn size={2} />

        <Input<ChangePasswordRequest>
          control={control}
          name="new_password_confirmation"
          placeHolder={t('screens.changePassword.confirmPassword')}
          rules={{
            required: true,
            validate: val => {
              if (watch('new_password') !== val) {
                return t('form.errors.confirmPasswordDoesntMatch');
              }
            },
          }}
        />
      </Content>
      <BigButton
        text={t('common.submit')}
        onPress={handleSubmit(onPressSubmit)}
        isLoading={isLoading}
      />
    </Container>
  );
};

const Container = styled.View(({ theme: { colors, layout } }) => ({
  backgroundColor: colors.primaryBackground,
  padding: layout.contentPadding,
  flex: 1,
  justifyContent: 'space-between',
}));
const LogoContainer = styled.View({
  marginTop: 20,
  alignItems: 'center',
  marginBottom: 60,
});
const Content = styled.View({});
