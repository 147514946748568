import { registerSheet } from 'react-native-actions-sheet';
import CategorySelectionSheet from './AddressSheets/CategorySelectionSheet';
import { PreviewSheet } from './AddressSheets/PreviewSheet';
import { ProfileSheet } from './ProfileSheet';
import { SheetManagerType } from './utils';

registerSheet('profile-sheet' as keyof SheetManagerType, ProfileSheet);
registerSheet('category-selection-sheet' as keyof SheetManagerType, CategorySelectionSheet);
registerSheet('preview-sheet' as keyof SheetManagerType, PreviewSheet);

export {};
