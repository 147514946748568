// libraries
import React from 'react';
import styled from 'styled-components/native';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Linking, Pressable, View } from 'react-native';
import { showMessage } from 'react-native-flash-message';

// components
import { BigButton, CustomPressable, CustomText, Input, SpacerColumn } from '@components/atoms';
import { Logo } from '@components/atoms/Logo';
import { Checkbox } from '@components/molecules';

// styles
import { genericStyles } from '@styles/genericStyles';

// misc
import { useAppNavigation } from '@utils/navigation';
import { LoginFormType } from './types';
import { LoginRequest, useLogin } from '@api/useLogin';
import { useFormError } from '@hooks/useFormError';
import { TermsAndConditionCheckbox } from './components/TermsAndConditionCheckbox';

export const SignInScreen = () => {
  // variables
  const navigation = useAppNavigation();

  const { control, handleSubmit, setError, reset } = useForm<LoginFormType>({
    mode: 'all',
  });
  const { t } = useTranslation();
  const { mutate, isLoading, error } = useLogin({
    onSuccess: () => {
      showMessage({
        message: t('success.signin'),
        type: 'success',
        duration: 1000,
      });
      reset();
    },
  });
  useFormError<LoginFormType, LoginRequest>(error, setError, reset);

  // functions
  const onPressSubmit = ({ email, password }: LoginFormType) => {
    mutate({ email, password });
  };

  // returns
  return (
    <Container>
      <Content>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <CustomText size={36} font="heading" textAlign="center">
          {t('screens.signIn.title')}
        </CustomText>
        <SpacerColumn size={5} />

        <Input<LoginFormType>
          control={control}
          name="email"
          placeHolder={t('screens.signIn.email')}
          rules={{ required: true }}
        />
        <SpacerColumn size={2.5} />
        <Input<LoginFormType>
          control={control}
          name="password"
          placeHolder={t('screens.signIn.password')}
          secureTextEntry
          rules={{ required: true }}
        />
        <SpacerColumn size={1} />

        <TermsAndConditionCheckbox control={control} />
        <SpacerColumn size={4} />

        <BigButton
          text={t('common.submit')}
          onPress={handleSubmit(onPressSubmit)}
          isLoading={isLoading}
        />
        <SpacerColumn size={2} />
        <CustomPressable onPress={() => navigation.navigate('auth', { screen: 'forgotPassword' })}>
          <CustomText font="bodyRegular" color="primary" textAlign="center">
            {t('common.forgotPassword')} ?
          </CustomText>
        </CustomPressable>
      </Content>

      <CustomPressable
        style={genericStyles.rowJcCenter}
        onPress={() => navigation.navigate('auth', { screen: 'signUp' })}>
        <CustomText font="bodyRegular">{t('screens.signIn.dontHaveAccount')} </CustomText>
        <CustomText font="bodyRegular" color="primary">
          {t('common.signUp')}
        </CustomText>
      </CustomPressable>
    </Container>
  );
};

const Container = styled.View(({ theme: { colors, layout } }) => ({
  backgroundColor: colors.primaryBackground,
  padding: layout.contentPadding,
  flex: 1,
  justifyContent: 'space-between',
}));
const LogoContainer = styled.View({
  marginTop: 20,
  alignItems: 'center',
  marginBottom: 40,
});
const Content = styled.View({});
