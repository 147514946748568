// libraries
import { useMutation } from 'react-query';

// misc
import { useErrorHandler } from '@hooks/useErrorHandler';
import request from '@utils/request';
import { PinType, ServerResponse } from './types';

export type UpdatedImageResponse = unknown;

export interface UpdatedImageRequest {
  pinId: number;
  image: string;
}

export const useUpdateImage = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: UpdatedImageResponse) => void;
  onError?: (res: ServerResponse<unknown, UpdatedImageRequest>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();

  // request
  const query = useMutation<
    UpdatedImageResponse,
    ServerResponse<unknown, UpdatedImageRequest> | undefined,
    UpdatedImageRequest
  >(
    [`updateImage`],
    async ({ pinId, image }: UpdatedImageRequest) => {
      const data = await request<ServerResponse<UpdatedImageResponse, UpdatedImageRequest>>({
        method: 'post',
        url: `/pins/update-image/${pinId}`,
        data: { image },
      });

      return data.data;
    },
    {
      onError: error => {
        triggerError({ error });
        onError && onError(error);
      },
      onSuccess: onSuccess,
    },
  );

  // return
  return query;
};
