// libraries
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/native';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

// components
import { Avatar, BigButton, Input, SpacerColumn } from '@components/atoms';
import { SelectInput } from '@components/molecules';

// misc
import { useAppNavigation } from '@utils/navigation';
import { AVAILABLE_LANGUAGE } from '@utils/language';
import { useUser } from '@context/UserProvider';
import { UpdateUserRequest, useUpdateUser } from '@api/useUpdateUser';
import { useFormError } from '@hooks/useFormError';
import { getFullName } from '@utils/text';
import { avatarData } from '@utils/constatnt';
import { generateInitialImage } from '@utils/image';
import { useGetCategoryList } from '@api/useGetCategoryList';
import { TouchableOpacity, StyleSheet, Image, FlatList, Dimensions } from 'react-native';

const Item = ({ item, onPress, backgroundColor, textColor }) => (
  <TouchableOpacity onPress={onPress} style={[styles.item, { backgroundColor }]}>
    <Image style={[styles.image, { color: textColor }]} source={item.icon} />
  </TouchableOpacity>
);

const windowDimensions = Dimensions.get('window');
export const EditProfileScreen = () => {
  // variables
  const navigation = useAppNavigation();
  const { user } = useUser();
  const [dimensions, setDimensions] = useState({
    window: windowDimensions,
  });
  const [editImage, setEditImage] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(user.image == null ? '0' : user.image);
  const { control, setValue, handleSubmit, setError, reset } = useForm<UpdateUserRequest>({
    defaultValues: user,
    mode: 'all',
  });
  const { t } = useTranslation();
  const { refetch } = useGetCategoryList();
  const { mutate, isLoading, error } = useUpdateUser({
    onSuccess: () => {
      refetch();
      showMessage({
        message: t('success.updateProfile'),
        type: 'success',
        duration: 2000,
      });
      navigation.navigate('drawer', { screen: 'home' });
    },
  });
  useFormError<UpdateUserRequest>(error, setError, reset);

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setDimensions({ window });
    });
    return () => subscription?.remove();
  });

  // functions
  const onPressSubmit = (data: UpdateUserRequest) => {
    data.image = String(selectedImageId);
    mutate(data);
  };

  const renderItem = ({ item }) => {
    const backgroundColor = item.id === selectedImageId ? '#6e3b6e' : 'lightgray';
    const color = item.id === selectedImageId ? 'white' : 'black';

    if (item.id === 0) {
      item.icon = generateInitialImage(getFullName(user));
    }

    return (
      <Item
        item={item}
        onPress={() => {
          setSelectedImageId(item.id);
          setEditImage(false);
        }}
        backgroundColor={backgroundColor}
        textColor={color}
      />
    );
  };
  const numColumns = Math.floor((dimensions.window.width - 150) / 100);
  // returns
  return (
    <Container>
      <Content>
        <TouchableOpacity onPress={() => setEditImage(true)}>
          <AvatarContainer>
            <Avatar
              uri={
                selectedImageId !== '0'
                  ? avatarData[selectedImageId].icon
                  : generateInitialImage(getFullName(user))
              }
              size="extra-large"
            />
          </AvatarContainer>
        </TouchableOpacity>

        {editImage && (
          <FlatList
            key={numColumns}
            columnWrapperStyle={{ flex: 1, justifyContent: 'space-around' }}
            data={avatarData}
            numColumns={numColumns}
            keyExtractor={item => item.id}
            renderItem={renderItem}
          />
        )}
        <Input<UpdateUserRequest>
          control={control}
          name="first_name"
          placeHolder={t('screens.editProfile.firstName')}
          icon="user"
          rules={{ required: true }}
        />
        <SpacerColumn size={2} />

        <Input<UpdateUserRequest>
          control={control}
          name="last_name"
          placeHolder={t('screens.editProfile.lastName')}
          icon="users"
          rules={{ required: true }}
        />
        <SpacerColumn size={2} />

        <Input<UpdateUserRequest>
          control={control}
          name="email"
          placeHolder={t('screens.editProfile.email')}
          icon="mail"
          rules={{ required: true }}
        />
        <SpacerColumn size={2} />

        <SelectInput<UpdateUserRequest>
          control={control}
          data={Object.keys(AVAILABLE_LANGUAGE).map(d => ({
            label: AVAILABLE_LANGUAGE[d].label,
            value: d,
          }))}
          name="language"
          placeHolder={t('screens.editProfile.language')}
          icon="globe"
          onChangeValue={setValue}
          sheetTitle={t('screens.editProfile.selectLang')}
          rules={{ required: true }}
        />
      </Content>

      <SpacerColumn size={2} />
      <BigButton
        text={t('screens.editProfile.submitBtn')}
        onPress={handleSubmit(onPressSubmit)}
        isLoading={isLoading}
      />
    </Container>
  );
};

const Container = styled.View(({ theme: { colors, layout } }) => ({
  backgroundColor: colors.primaryBackground,
  padding: layout.contentPadding,
  paddingVertical: layout.padding_x4_5,
  flex: 1,
  justifyContent: 'space-between',
}));

const AvatarContainer = styled.View({
  marginTop: 20,
  alignSelf: 'center',
  marginBottom: 60,
});

const styles = StyleSheet.create({
  item: {
    padding: 20,
    marginVertical: 8,
    marginHorizontal: 8,
  },
  image: {
    height: 90,
    width: 60,
  },
});

const Content = styled.View({});
