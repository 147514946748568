import React, { useState } from 'react';
import { Image } from 'react-native';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const ImageDialog = ({ pickedImage, open, onClose, ...restProps }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} style={{ zIndex: 9999 }}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <Image
        style={{
          resizeMode: 'contain',
          width: '300px',
          height: '330px',
          marginTop: '50px',
          paddingBottom: '10px',
        }}
        source={{ uri: pickedImage }}
      />
    </Dialog>
  );
};
