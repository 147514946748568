export const fonts = {
  family: {
    heading: 'Medel',
    bodyBold: 'Poppins-Bold',
    bodyMedium: 'Poppins-Medium',
    bodyRegular: 'Poppins-Regular',
    bodyLight: 'Poppins-Light',
    bodyItalic: 'Poppins-Italic',
  },
};
