// libraries
import { useMutation } from 'react-query';

// misc
import { useErrorHandler } from '@hooks/useErrorHandler';
import request from '@utils/request';
import { ServerResponse } from './types';

export type ChangePasswordResponse = [];

export interface ChangePasswordRequest {
  old_password: string;
  new_password: string;
  new_password_confirmation: string;
}

export const useChangePassword = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: ChangePasswordResponse) => void;
  onError?: (res: ServerResponse<unknown, ChangePasswordRequest>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();

  // request
  const query = useMutation<
    ChangePasswordResponse,
    ServerResponse<unknown, ChangePasswordRequest> | undefined,
    ChangePasswordRequest
  >(
    [`change-password`],
    async (requestData: ChangePasswordRequest) => {
      const data = await request<ServerResponse<ChangePasswordResponse, ChangePasswordRequest>>({
        method: 'post',
        url: `/change-password`,
        data: requestData,
      });

      return data.data;
    },
    {
      onError: error => {
        triggerError({ error });
        onError && onError(error);
      },
      onSuccess: onSuccess,
    },
  );

  // return
  return query;
};
