// libraries
import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import styled from 'styled-components/native';

// components
import { FullScreenLoader, MapHeader, CustomMarker } from '@components/molecules';
import { CustomIcon, CustomPressable, Loading } from '@components/atoms';
import { FilterModalScreen, FilterType } from './components/FilterModalScreen';

// misc
import { CustomSheetManager } from '@components/organisms/sheets/utils';
import { useUser } from '@context/UserProvider';
import { useGetCategoryList } from '@api/useGetCategoryList';
import { useAppNavigation } from '@utils/navigation';
import { CategoryType } from '@api/types';
import { useCurrentLocation } from '@hooks/useCurrentLocation';
import { useGetPins } from '@api/useGetPins';
import { APP_ONBOARDING_KEY } from '@utils/keys';

export const HomeScreen = () => {
  // variables
  const { data: catData } = useGetCategoryList();
  const mainCategoryRef = useRef<CategoryType>();
  const navigation = useAppNavigation();
  const { location, isLoading: isCurrentLocationLoading } = useCurrentLocation();
  const { user, isLoading: isLoadingUser } = useUser();
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterSettings, setFilterSettings] = useState<FilterType>({ category_id: null });
  const { data, isFetching } = useGetPins(filterSettings.category_id);

  // effects
  useEffect(() => {
    const isOnboardingShown = localStorage.getItem(APP_ONBOARDING_KEY);

    if (isOnboardingShown !== 'true') {
      setTimeout(() => {
        navigation.navigate('drawer', { screen: 'onboarding' });
      }, 1000);
    }
  }, []);

  // functions
  const toggleFilter = () => setFilterVisible(!filterVisible);
  const onFilterChange = (settings: FilterType) => {
    toggleFilter();
    setFilterSettings(settings);

    useGetPins(settings.category_id);
  };

  const onSelectSubCategoryCategory = async (subCategoryId: number) => {
    await CustomSheetManager.hide('category-selection-sheet');

    if (mainCategoryRef.current) {
      const subCategory = mainCategoryRef.current.sub_categories.find(c => c.id === subCategoryId);
      navigation.navigate('drawer', {
        screen: 'home',
        params: {
          screen: 'addressSelection',
          params: {
            mainCategory: mainCategoryRef.current,
            subCategory,
            location,
          },
        },
      });
    }
  };

  const onSelectCategory = async (categoryId: number) => {
    await CustomSheetManager.hide('category-selection-sheet');
    const cat = catData.find(d => d.id === categoryId);
    const subCategoryData = cat.sub_categories;

    mainCategoryRef.current = cat;

    setTimeout(async () => {
      await CustomSheetManager.show('category-selection-sheet', {
        categories: subCategoryData,
        onPress: onSelectSubCategoryCategory,
        isSubCategory: true,
      });
    }, 1000);
  };

  const onPressPlus = () => {
    if (user) {
      CustomSheetManager.show('category-selection-sheet', {
        categories: catData,
        onPress: onSelectCategory,
      });
    } else if (!isLoadingUser) {
      navigation.navigate('auth', { screen: 'signUp' });
    }
  };

  // returns
  if (isCurrentLocationLoading) {
    return <FullScreenLoader />;
  }
  return (
    <Container>
      {isFetching && <FetchLoader />}
      <MapHeader onPressFilter={toggleFilter} />
      <PlusIconContainer>
        <CustomPressable onPress={onPressPlus}>
          <CustomIcon name="plus" reverse raised size={34} />
        </CustomPressable>
      </PlusIconContainer>

      <GoogleMap
        mapContainerStyle={{ flex: 1 }}
        center={location}
        zoom={10}
        options={{
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          zoomControl: false,
        }}>
        {data?.map(pin =>
          pin.category.status === 'active' ? (
            <CustomMarker
              key={pin.id}
              position={{ lat: parseFloat(pin.latitude), lng: parseFloat(pin.longitude) }}
              iconUrl={pin.category.image_url}
              onPress={() => CustomSheetManager.show('preview-sheet', { pin })}
            />
          ) : null,
        )}
      </GoogleMap>
      <FilterModalScreen
        isVisible={filterVisible}
        onClose={toggleFilter}
        onFilterChange={onFilterChange}
      />
    </Container>
  );
};

const Container = styled.View({
  flex: 1,
});

const PlusIconContainer = styled.View(({ theme: { layout } }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  bottom: layout.padding_x4,
  right: layout.padding_x3,
  zIndex: 1,
}));

const FetchLoader = styled(Loading)(({ theme: { layout } }) => ({
  position: 'absolute',
  top: layout.padding_x5,
  right: 0,
  left: 0,
  margin: 'auto',
  zIndex: 2,
}));
