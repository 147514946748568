// libraries
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';

// components
import {
  Avatar,
  CustomPressable,
  CustomText,
  DivColumn,
  DivRow,
  Loading,
  SpacerColumn,
  SpacerRow,
} from '@components/atoms';

// styles
import { genericStyles } from '@styles/genericStyles';

// misc
import { generateInitialImage } from '@utils/image';
import { reduceToTen } from '@utils/text';
import { User } from '@api/types';
import { useUser } from '@context/UserProvider';
import { CustomSheetManager } from '@components/organisms/sheets/utils';
import { useToggleFollow } from '@api/useToggleFollow';

interface ProfileItemProps {
  isLoading?: boolean;
  user: User;
}

export const ProfileItem: React.FC<ProfileItemProps> = props => {
  // variables
  const { user } = useUser();

  const { first_name, last_name, pins_count, is_followed, id } = props?.user || {};
  const [isFollowed, setIsFollowed] = useState(is_followed);
  const hideFollowerBtn = !user?.id || user?.id === id;
  const { t } = useTranslation();
  const { mutate, isLoading: isLoadingFollow } = useToggleFollow({
    onSuccess: () => {
      setIsFollowed(prev => !prev);
    },
  });

  // hooks
  useEffect(() => {
    setIsFollowed(is_followed);
  }, [is_followed]);

  // functions
  const onToggleFollow = () =>
    mutate({ following_id: props.user?.id, operation: isFollowed ? 'unfollow' : 'follow' });

  // returns
  return (
    <Container
      onPress={
        isLoadingFollow
          ? undefined
          : () =>
              CustomSheetManager.show('profile-sheet', {
                user: props.user,
                onFollowChange: setIsFollowed,
              })
      }>
      <DivRow justifyContent="center" style={genericStyles.w100}>
        <Avatar uri={generateInitialImage(first_name + ' ' + last_name)} size="regular" />
        <SpacerRow size={1} />
        <DivColumn style={genericStyles.flexWrap}>
          <CustomText font="bodyRegular" size={16} numberOfLines={1}>
            {first_name} {last_name}
          </CustomText>

          {pins_count > 0 ? (
            <>
              <SpacerColumn size={0.5} />
              <CustomText font="bodyRegular" size={12} color="silver">
                {t('sheets.categorySelection.addedPins', { pins: reduceToTen(pins_count) })}
              </CustomText>
            </>
          ) : null}
        </DivColumn>

        <SpacerRow size={1} />
        {!hideFollowerBtn === true && (
          <CustomPressable onPress={onToggleFollow}>
            {isLoadingFollow ? (
              <Loading />
            ) : (
              <CustomText color={isFollowed ? 'silver' : 'primary'} font="bodyMedium" size={16}>
                {isFollowed ? t('common.unfollow') : t('common.follow')}
              </CustomText>
            )}
          </CustomPressable>
        )}
      </DivRow>
    </Container>
  );
};

const Container = styled(CustomPressable)(({ theme: { layout } }) => ({
  ...genericStyles.rowWithCenterAndSB,
}));
