// libraries
import React from 'react';
import styled from 'styled-components/native';
import { OverlayView, OverlayViewProps } from '@react-google-maps/api';

// styles
import { genericStyles } from '@styles/genericStyles';

interface CustomMarkerProps extends Omit<OverlayViewProps, 'mapPaneName'> {
  iconUrl: string;
  onPress?: () => void;
}

export const CustomMarker: React.FC<CustomMarkerProps> = ({ iconUrl, onPress, ...props }) => {
  if (!props.position.lat || !props.position.lng) {
    return null;
  }

  return (
    <OverlayView {...props} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
      <Container onPress={onPress}>
        <IconContainer>
          <Icon source={{ uri: iconUrl }} />
        </IconContainer>
        <DownTriangle
          style={{
            transform: [{ rotate: '180deg' }],
          }}
        />
      </Container>
    </OverlayView>
  );
};

const Container = styled.Pressable({});

const Icon = styled.Image(() => ({
  width: 50,
  height: 50,
  borderRadius: 20,
}));

const IconContainer = styled.View(({ theme: { colors } }) => ({
  ...genericStyles.jcAiCenter,
  backgroundColor: colors.white,
  width: 50,
  height: 50,
  borderRadius: 25,
  zIndex: 2,
}));

const DownTriangle = styled.View(({ theme: { colors } }) => ({
  width: 0,
  height: 0,
  backgroundColor: 'transparent',
  borderStyle: 'solid',
  borderLeftWidth: 8 * 3,
  borderRightWidth: 8 * 3,
  borderBottomWidth: 16 * 3,
  borderLeftColor: 'transparent',
  borderRightColor: 'transparent',
  borderBottomColor: 'transparent',
  alignSelf: 'center',
  marginTop: -13 * 3,
  zIndex: 1,
}));
