// libraries
import React from 'react';
import styled from 'styled-components/native';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextStyle, ViewStyle } from 'react-native';

// components
import { CustomIcon, CustomText, ErrorText, SpacerRow } from '@components/atoms';

// styles
import { genericStyles } from '@styles/genericStyles';

//types
interface CheckboxProps {
  text: string | React.ReactNode;
  control: Control<any>;
  name: string;
  rules?: { required: boolean };
  defaultValue: boolean;
  containerStyle?: ViewStyle;
  errorStyle?: TextStyle;
}

export const Checkbox = ({
  text,
  name,
  control,
  rules,
  defaultValue,
  containerStyle,
  errorStyle,
}: CheckboxProps): React.ReactElement => {
  // variables
  const { t } = useTranslation();
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
    rules,
  });

  // renders
  return (
    <Container style={containerStyle}>
      <ButtonContainer onPress={() => field.onChange(!field.value)}>
        {typeof text === 'function' ? (
          text
        ) : (
          <CustomText size={14} color={fieldState.error?.type ? 'error' : 'text'}>
            {text}
          </CustomText>
        )}
        <SpacerRow size={0.4} />
        <CustomIcon
          name={field.value === true ? 'check-square' : 'square'}
          color={fieldState.error?.type ? 'error' : 'text'}
        />
      </ButtonContainer>
      <ErrorText style={errorStyle}>
        {(fieldState.error?.type && t('form.errors.required')) || ''}
      </ErrorText>
    </Container>
  );
};

const Container = styled.View(() => ({}));

const ButtonContainer = styled.Pressable(({ theme: { layout } }) => ({
  ...genericStyles.rowWithCenterAndSB,
  paddingVertical: layout.padding * 0.2,
}));
