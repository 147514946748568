// libraries
import React, { useEffect, useState, useMemo } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { useForm } from 'react-hook-form';
import { StatusBar, ListRenderItemInfo, FlatList } from 'react-native';
import { useTranslation } from 'react-i18next';

// components
import {
  FilterCheckbox,
  CustomModal,
  CustomModalProps,
  FullScreenLoader,
} from '@components/molecules';
import {
  BigButton,
  CustomIcon,
  CustomText,
  DivColumn,
  Divider,
  SpacerColumn,
} from '@components/atoms';

// styles
import { genericStyles } from '@styles/genericStyles';

// misc
import { useGetCategoryList } from '@api/useGetCategoryList';
import { useUser } from '@context/UserProvider';

interface FilterModalScreenProps extends Omit<CustomModalProps, 'children'> {
  onFilterChange: (settings: FilterType) => void;
}

export type FilterType = {
  category_id: FilterCategory['id'][];
};

export type FilterCategory = {
  id: 'follower' | 'following' | number;
  name: string;
  status: string;
};

export const FilterModalScreen = ({ onFilterChange, ...restProps }: FilterModalScreenProps) => {
  // variables
  const { t } = useTranslation();
  const [currentFilter, setCurrentFilter] = useState<FilterType>();
  const { user } = useUser();

  const { colors } = useTheme();
  const { control, setValue, handleSubmit, getValues } = useForm<FilterType>({
    mode: 'all',
    defaultValues: {
      category_id: [],
    },
  });

  const { data } = useGetCategoryList();
  const filterList = useMemo<FilterCategory[]>(() => {
    if (!user) {
      return data || [];
    }

    return data
      ? [
          ...data,
          { id: 'follower', name: t('common.myFollowers') },
          { id: 'following', name: t('common.myFollowing') },
        ]
      : [];
  }, [data, user]);

  // hooks
  useEffect(() => {
    setValue('category_id', currentFilter?.category_id || []);
  }, [restProps.isVisible]);

  // functions
  const onApply = (formValues: any) => {
    const categoryIdss = getValues('category_id').filter(v => v !== null);
    setCurrentFilter({ category_id: formValues?.category_id });
    onFilterChange({ category_id: categoryIdss });
  };

  const onReset = async () => {
    setCurrentFilter({ category_id: [] });
    onFilterChange({ category_id: null });
  };

  const renderCategory = ({ item, index }: ListRenderItemInfo<FilterCategory>) => (
    <DivColumn key={item.id}>
      <FilterCheckbox
        control={control}
        name={'category_id.' + index}
        text={item.name}
        defaultValue={item.id}
      />
      <SpacerColumn size={0.25} />
    </DivColumn>
  );

  // returns
  return (
    <CustomModal
      {...restProps}
      renderHeader={() => null}
      animationIn="slideInUp"
      animationOut="slideOutDown">
      <HeaderContainer style={genericStyles.rowWithCenterAndSB}>
        <CustomIcon name="x" onPress={restProps.onClose} />
        <CustomText color="text" font="bodyBold" size={16}>
          {t('common.filters')}
        </CustomText>
        <CustomText color="text" font="bodyRegular" onPress={onReset}>
          {t('common.reset')}
        </CustomText>
      </HeaderContainer>
      <Divider />
      {restProps.isVisible && <StatusBar backgroundColor={colors.white} barStyle="dark-content" />}
      <FullScreenLoader isLoading={false}>
        <>
          <Container>
            <SpacerColumn size={0.5} />
            <Heading color="text" font="bodyMedium" size={16} />
            {filterList !== undefined && (
              <FlatList
                data={filterList}
                renderItem={renderCategory}
                keyExtractor={item => item.id.toString()}
              />
            )}
          </Container>
          <Footer>
            <BigButton text="Apply" onPress={handleSubmit(onApply)} />
          </Footer>
        </>
      </FullScreenLoader>
    </CustomModal>
  );
};

const Container = styled.View(({ theme: { layout, colors } }) => ({
  flex: 1,
  backgroundColor: colors.primaryBackground,
  paddingHorizontal: layout.contentPadding * 0.5,
}));

const HeaderContainer = styled.View(({ theme: { layout, colors } }) => ({
  backgroundColor: colors.primaryBackground,
  paddingVertical: layout.contentPadding * 0.5,
  paddingHorizontal: layout.contentPadding * 0.5,
}));

const Heading = styled(CustomText)(({ theme: { layout } }) => ({
  ...genericStyles.upperCase,
  paddingBottom: layout.padding * 0.2,
}));

const Footer = styled.View(({ theme: { layout, colors } }) => ({
  // todo-ios: implement ios shadow
  elevation: 0.5,
  paddingHorizontal: layout.contentPadding * 0.5,
  paddingVertical: layout.contentPadding * 0.5,
  backgroundColor: colors.primaryBackground,
}));
