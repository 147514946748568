// libraries
import React from 'react';
import styled from 'styled-components/native';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// components
import { CustomIcon, CustomText, ErrorText, SpacerRow } from '@components/atoms';

// styles
import { genericStyles } from '@styles/genericStyles';

//types
export interface CheckboxProps {
  text: string | React.ReactNode;
  control: Control<any>;
  name: string;
  rules?: { required: boolean };
  defaultValue: any;
}

export const FilterCheckbox = ({
  text,
  name,
  control,
  rules,
  defaultValue,
}: CheckboxProps): React.ReactElement => {
  // variables
  const { t } = useTranslation();
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue: null,
  });

  // renders
  return (
    <Container>
      <ButtonContainer
        onPress={() => {
          field.onChange(field.value ? null : defaultValue);
        }}>
        {typeof text === 'function' ? (
          text
        ) : (
          <CustomText size={14} color={fieldState.error?.type ? 'error' : 'text'}>
            {text}
          </CustomText>
        )}
        <SpacerRow size={0.4} />
        <CustomIcon
          name={field.value === defaultValue ? 'check-square' : 'square'}
          color={fieldState.error?.type ? 'error' : 'text'}
        />
      </ButtonContainer>
      <ErrorText>{(fieldState.error?.type && t('form.errors.required')) || ''}</ErrorText>
    </Container>
  );
};

const Container = styled.View(() => ({}));

const ButtonContainer = styled.Pressable(({ theme: { layout } }) => ({
  ...genericStyles.rowWithCenterAndSB,
  paddingVertical: layout.padding * 0.2,
}));
