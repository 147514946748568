// libraries
import { useMutation } from 'react-query';

// misc
import { useErrorHandler } from '@hooks/useErrorHandler';
import request from '@utils/request';
import { ServerResponse } from './types';
import { LoginResponse } from './useLogin';
import { useUser } from '@context/UserProvider';

export type CreateUserResponse = LoginResponse;

export interface CreateUserRequest {
  first_name?: string;
  last_name?: string;
  email: string;
  language?: string;
  password?: string;
}

export const useCreateUser = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: CreateUserResponse) => void;
  onError?: (res: ServerResponse<unknown, CreateUserRequest>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();
  const { getAuthUser } = useUser();

  // request
  const query = useMutation<
    CreateUserResponse,
    ServerResponse<unknown, CreateUserRequest> | undefined,
    CreateUserRequest
  >(
    [`users`],
    async (requestData: CreateUserRequest) => {
      const data = await request<ServerResponse<CreateUserResponse, CreateUserRequest>>({
        method: 'post',
        url: `/users`,
        data: requestData,
      });

      // because this api user doesn't have follower and following count
      await getAuthUser({ token: data.data.access_token, id: data.data.user?.id });
      return data.data;
    },
    {
      onError: error => {
        triggerError({ error });
        onError && onError(error);
      },
      onSuccess: onSuccess,
    },
  );

  // return
  return query;
};
