// libraries
import { useInfiniteQuery } from 'react-query';

// misc
import { useErrorHandler } from '@hooks/useErrorHandler';
import request from '@utils/request';
import { PaginationResponse, User } from './types';

type GetFollowingsResponse = PaginationResponse<User>;

export const useGetFollowings = (userId: number) => {
  // variables
  const { triggerError } = useErrorHandler();

  // request
  const query = useInfiniteQuery<GetFollowingsResponse>(
    [`list-followings`, userId],
    async ({ pageParam }) => {
      try {
        const data = await request<GetFollowingsResponse>({
          method: 'get',
          url: `/list-followings/${userId}`,
          params: {
            page: pageParam,
          },
        });

        return data;
      } catch (error) {
        triggerError(error);
      }
    },
    {
      getNextPageParam: lastPage =>
        lastPage.meta.last_page > lastPage.meta.current_page
          ? lastPage.meta.current_page + 1
          : undefined,
      refetchOnWindowFocus: false,
    },
  );

  // return
  return query;
};
