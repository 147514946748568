// libraries
import React from 'react';
import { useTheme } from 'styled-components/native';

// components
import { CustomIcon } from './CustomIcon';
import { MenuIconContainer } from './MenuIconContainer';

// styles
import { ColorPalette } from '@styles/types';

// misc
import { useAppNavigation } from '@utils/navigation';

export const BackBtn = ({ color = 'white' }: { color?: ColorPalette }) => {
  // variables
  const { goBack, canGoBack, navigate } = useAppNavigation();
  const { layout } = useTheme();

  // returns
  return (
    <MenuIconContainer
      onPress={canGoBack() ? goBack : () => navigate('drawer', { screen: 'home' })}
      style={{ marginLeft: layout.padding_x3 }}
      color="primary">
      <CustomIcon name="chevron-left" color={color} size={30} />
    </MenuIconContainer>
  );
};
