import axiosClient from 'axios';
import type { AxiosRequestConfig } from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { APP_AUTH_TOKEN, APP_LANGUAGE_KEY } from './keys';
import { defaultNS } from '@localization/i18n';

/**
 * Creates an initial 'axios' instance with custom settings.
 */
const instance = axiosClient.create({
  baseURL: 'https://voila.tips/api',
  headers: {
    Accept: 'application/json',
  },
});

/**
 * add bearer token for authenticated requests
 */

instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  try {
    const token = await AsyncStorage.getItem(APP_AUTH_TOKEN);
    const language = await AsyncStorage.getItem(APP_LANGUAGE_KEY);
    config.headers['Authorization'] = `Bearer ${token}`;
    config.params = { language: language || defaultNS };
  } catch (e) {
    // saving error
  }

  return config;
});

/**
 * Handle all responses. It is possible to add handlers
 * for requests, but it is omitted here for brevity.
 */
instance.interceptors.response.use(
  res => res.data,
  err => {
    if (err.response) {
      return Promise.reject(err.response.data);
    }

    if (err.request) {
      return Promise.reject(err.request);
    }

    return Promise.reject(err.message);
  },
);

/**
 * Replaces main `axios` instance with the custom-one.
 *
 * @param cfg - Axios configuration object.
 * @returns A promise object of a response of the HTTP request with the 'data' object already
 * destructured.
 */
const request = <T>(cfg: AxiosRequestConfig) => instance.request<unknown, T>(cfg);

export default request;
