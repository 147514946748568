import { generateInitialImage } from './image';
import { DEMO_FULL_NAME } from './fakes';

// const { user } = useUser();
export const avatarData = [
  {
    id: 0,
    icon: generateInitialImage(DEMO_FULL_NAME),
  },
  {
    id: 1,
    icon: require('@assets/images/avatar/avatar1.png'),
  },
  {
    id: 2,
    icon: require('@assets/images/avatar/avatar2.png'),
  },
  {
    id: 3,
    icon: require('@assets/images/avatar/avatar3.png'),
  },
  {
    id: 4,
    icon: require('@assets/images/avatar/avatar4.png'),
  },
  {
    id: 5,
    icon: require('@assets/images/avatar/avatar5.png'),
  },
  {
    id: 6,
    icon: require('@assets/images/avatar/avatar6.png'),
  },
  {
    id: 7,
    icon: require('@assets/images/avatar/avatar7.png'),
  },
  {
    id: 8,
    icon: require('@assets/images/avatar/avatar8.png'),
  },
  {
    id: 9,
    icon: require('@assets/images/avatar/avatar9.png'),
  },
];
