// libraries
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as ImagePicker from 'expo-image-picker';
import { useTranslation } from 'react-i18next';
import { ActionSheetRef, SheetProps, useScrollHandlers } from 'react-native-actions-sheet';
import { Linking, ScrollView, StyleSheet, View, Image, Pressable } from 'react-native';
import { GoogleMap } from '@react-google-maps/api';
import { useConfirm } from '@sj-distributor/react-native-confirm-modal';
import { showMessage } from 'react-native-flash-message';
import { useQueryClient } from 'react-query';
import { useTheme } from 'styled-components/native';

// components
import {
  BigButton,
  CustomIcon,
  CustomText,
  DivRow,
  SpacerColumn,
  SpacerRow,
} from '@components/atoms';
import { CustomSheetManager } from '@components/organisms/sheets/utils';
import { CustomActionSheet, CustomMarker, ProfileItem } from '@components/molecules';
import { CategoryItem } from './CategoryItem';

// styles
import { genericStyles } from '@styles/genericStyles';

// misc
import { PinType } from '@api/types';
import { useGetUser } from '@api/useGetUser';
// import { CustomSheetManager } from '../utils';
import { useDeletePin } from '@api/useDeletePin';
import { useUpdateImage } from '@api/useUpdateImage';
import { useUser } from '@context/UserProvider';
import { layout } from '@styles/layout';
import { ImageDialog } from '@components/image/ImageDialog';

export type PinPreviewType = Pick<
  PinType,
  'id' | 'address' | 'latitude' | 'longitude' | 'category' | 'sub_category' | 'user' | 'image'
>;

export interface PreviewSheetProps {
  pin: PinPreviewType;
  onPressSubmit?: (pin: PinPreviewType) => void;
}

export const PreviewSheet: React.FC<SheetProps<PreviewSheetProps>> = ({
  sheetId,
  payload: { pin, onPressSubmit },
}) => {
  // variables
  const actionSheetRef = useRef<ActionSheetRef>(null);
  const [pickedImage, setPickedImage] = useState<string>(pin.image !== null ? pin.image : null);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const scrollHandlers = useScrollHandlers<ScrollView>('previewSheet-1', actionSheetRef);
  const { colors } = useTheme();
  const { confirm } = useConfirm();
  const { t } = useTranslation();
  const { data, isFetching } = useGetUser(pin.user?.id);

  const { user } = useUser();
  const queryClient = useQueryClient();
  const { mutate: deletepin } = useDeletePin({
    onSuccess: () => {
      showMessage({
        message: t('success.deletePin'),
        type: 'success',
        duration: 1000,
      });
      queryClient.refetchQueries(['pins']);
    },
  });

  const { mutate: updateimage } = useUpdateImage({
    onSuccess: () => {
      showMessage({
        message: t('success.savePin'),
        type: 'success',
        duration: 1000,
      });
      queryClient.refetchQueries(['pins']);
    },
  });

  // returns
  const Label = useCallback(
    ({ title, children }: { title: string; children: React.ReactNode }) => (
      <>
        <CustomText font="bodyMedium" size={16} exactLineHeight>
          {title}
        </CustomText>
        <SpacerColumn size={1.5} />
        {children}
        <SpacerColumn size={2} />
      </>
    ),
    [],
  );

  useEffect(() => {
    if (onPressSubmit === undefined) {
      if (!isFetching) {
        const pinsData = data.pins.filter(pins => pins.id === pin.id);
        if (pinsData[0].image !== null) {
          setPickedImage(pinsData[0].image);
        }
      }
    }
  }, [data, isFetching]);

  const pickImageAsync = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      quality: 1,
    });

    if (!result.canceled) {
      setPickedImage(result.assets[0].uri);
      pin.image = result.assets[0].uri;
      onPressSubmit === undefined
        ? updateimage({
            pinId: pin.id,
            image: result.assets[0].uri,
          })
        : '';
    } else {
      alert('You did not select any image.');
    }
  };

  const setPinImage = () => {
    if (onPressSubmit !== undefined) {
      return true;
    } else {
      if (!isFetching) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <CustomActionSheet
      ref={actionSheetRef}
      id={sheetId}
      title={t('common.preview')}
      titleStyle={genericStyles.textCenter}>
      {/* {isFetching && <FetchLoader />} */}
      <ScrollView
        {...scrollHandlers}
        contentContainerStyle={{
          padding: layout.padding_x3,
        }}>
        {pin.user?.id && (
          <Label title={t('common.createdBy')}>
            <ProfileItem user={data ? data : pin.user} isLoading={isFetching} />
          </Label>
        )}
        <Label title={t('common.mainCategory')}>
          <CategoryItem
            uri={pin.category?.image_url || 'https://via.placeholder.com/150'}
            description={pin.category.name}
          />
        </Label>
        <Label title={t('common.subCategory')}>
          <CategoryItem description={pin.sub_category.name} />
        </Label>
        <DivRow justifyContent="center" style={genericStyles.w100}>
          {pickedImage != null ? (
            <Pressable onPress={() => setOpenImageDialog(true)}>
              <Image
                source={{
                  uri: setPinImage() ? pickedImage : require('@assets/images/loading.png'),
                }}
                style={{
                  width: 100,
                  height: 100,
                  resizeMode: 'contain',
                  border: '1px solid black',
                  borderRadius: '5px',
                  paddingBottom: '10px',
                }}
              />
            </Pressable>
          ) : null}
          <SpacerRow size={1} />
          <div style={{ width: pickedImage != null ? '60%' : '100%' }}>
            {pin?.user?.id === user?.id ? (
              <BigButton
                text={pickedImage != null ? t('common.editanimage') : t('common.pickanimage')}
                onPress={pickImageAsync}
              />
            ) : null}
          </div>
        </DivRow>
        <SpacerColumn size={2} />
        {onPressSubmit && (
          <BigButton text={t('common.submit')} onPress={() => onPressSubmit(pin)} />
        )}
        <SpacerColumn size={1.5} />
        <Label title={t('common.address')}>
          <View style={genericStyles.rowWithCenter}>
            <CustomIcon name="map-pin" color="secondary" />
            <SpacerRow size={1} />
            <CustomText size={16} color="silver">
              {pin.address}
            </CustomText>
          </View>
        </Label>

        <GoogleMap
          mapContainerStyle={styles.mapStyle}
          center={{ lat: parseFloat(pin.latitude), lng: parseFloat(pin.longitude) }}
          zoom={10}
          options={{
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
          }}>
          <CustomMarker
            key={pin.id}
            position={{ lat: parseFloat(pin.latitude), lng: parseFloat(pin.longitude) }}
            iconUrl={pin.category.image_url}
          />
        </GoogleMap>
        <SpacerColumn size={2} />

        {!onPressSubmit && (
          <>
            <BigButton
              text={t('common.viewOnGoogleMaps')}
              onPress={() =>
                Linking.openURL(`http://www.google.com/maps/place/${pin.latitude},${pin.longitude}`)
              }
              color="secondary"
            />
            <SpacerColumn size={1} />
            {pin?.user?.id === user?.id && data && (
              <BigButton
                text={t('sheets.preview.deletePin')}
                onPress={() => {
                  CustomSheetManager.hideAll();
                  confirm({
                    title: t('common.areYouSure'),
                    confirmText: t('common.delete'),
                    cancelText: t('common.cancel'),
                    description: t('common.youCantUndo'),
                    onConfirm: () => {
                      deletepin({ pinId: pin.id });
                    },
                    cancelStyle: {
                      width: 150,
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                    confirmStyle: {
                      width: 150,
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                    confirmTextStyle: {
                      color: colors.error,
                    },
                  });
                }}
                color="error"
              />
            )}
          </>
        )}

        {openImageDialog && (
          <ImageDialog
            open={openImageDialog}
            onClose={() => setOpenImageDialog(false)}
            pickedImage={pickedImage}
          />
        )}
      </ScrollView>
    </CustomActionSheet>
  );
};

const styles = StyleSheet.create({
  mapStyle: { width: '100%', height: 100, borderRadius: 16 },
});

// const FetchLoader = styled(Loading)(({ theme: { layout } }) => ({
//   position: 'absolute',
//   top: layout.padding_x5,
//   right: 0,
//   left: 0,
//   margin: 'auto',
//   zIndex: 2,
// }));
