// libraries
import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete, GoogleMap, Marker } from '@react-google-maps/api';
import { TextInput } from 'react-native';
import styled from 'styled-components/native';
import axios, { AxiosResponse } from 'axios';
import { showMessage } from 'react-native-flash-message';
import { GOOGLE_API_KEY } from '@env';
import { useTranslation } from 'react-i18next';
import { CompositeNavigationProp, RouteProp } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useQueryClient } from 'react-query';
import * as Location from 'expo-location';

// components
import { CustomIcon, CustomPressable, InputStyle } from '@components/atoms';
import { PinPreviewType } from '@components/organisms/sheets/AddressSheets/PreviewSheet';

// misc
import { ReverseGeocodeResponse } from './types';
import { fonts } from '@styles/fonts';
import { DrawerParamList, HomeStackParamList, RootStackParamList } from '@utils/navigation';
import { useSavePin } from '@api/useSavePin';
import { CustomSheetManager } from '@components/organisms/sheets/utils';
import { useUser } from '@context/UserProvider';

export type AddressSelectionScreenProp = {
  navigation: CompositeNavigationProp<
    NativeStackNavigationProp<RootStackParamList, 'drawer'>,
    CompositeNavigationProp<
      DrawerNavigationProp<DrawerParamList, 'home'>,
      NativeStackNavigationProp<HomeStackParamList, 'addressSelection'>
    >
  >;
  route: RouteProp<HomeStackParamList, 'addressSelection'>;
};

export const AddressSelectionScreen: React.FC<AddressSelectionScreenProp> = ({
  navigation,
  route,
}) => {
  // variables
  const inputRef = useRef<TextInput>(null);
  const locationIntervalRef = useRef<NodeJS.Timer>(null);
  const [location, setLocation] = useState<Location.LocationObject>(null);
  const [searchResult, setSearchResult] = useState<google.maps.places.Autocomplete>();
  const [markedDetail, setMarkedDetail] = useState<{ lat: number; lng: number; address: string }>();
  const [mapLocation, setMapLocation] = useState<{ lat: number; lng: number }>(
    route.params.location,
  );
  const { user } = useUser();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useSavePin({
    onSuccess: () => {
      showMessage({
        message: t('success.savePin'),
        type: 'success',
        duration: 1000,
      });
      queryClient.refetchQueries(['pins']);
      navigation.goBack();
    },
  });

  // hooks
  useEffect(() => {
    if (!route.params || !route.params.mainCategory || !route.params.location) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'drawer', params: { screen: 'home', params: { screen: 'pinList' } } }],
      });
    } else {
      setMarker(route.params.location.lat, route.params.location.lng);
    }
  }, []);

  // functions
  const onGetCurrentLocation = async () => {
    const { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== 'granted') {
      return;
    }

    const updatedLocation = await Location.getCurrentPositionAsync({});
    clearInterval(locationIntervalRef.current);

    setLocation(updatedLocation);
    setMapLocation({
      lat: updatedLocation.coords.latitude,
      lng: updatedLocation.coords.longitude,
    });
    setMarker(updatedLocation.coords.latitude, updatedLocation.coords.longitude);

    // locationIntervalRef.current = setInterval(() => {
    //   onGetCurrentLocation();
    // }, 5000);
  };

  const onPressCheck = () => {
    const pin = {
      id: Math.floor(Math.random() * (99999 - 1000 + 1) + 1000),
      user,
      category: route.params.mainCategory,
      sub_category: route.params.subCategory,
    };
    const place = searchResult.getPlace();

    if (markedDetail) {
      CustomSheetManager.show('preview-sheet', {
        pin: {
          ...pin,
          address: markedDetail.address,
          latitude: markedDetail.lat.toString(),
          longitude: markedDetail.lng.toString(),
          image: null,
        },
        onPressSubmit: onPressSubmit,
      });
    } else if (place) {
      CustomSheetManager.show('preview-sheet', {
        pin: {
          ...pin,
          address: place.formatted_address,
          latitude: place.geometry.location.lat().toString(),
          longitude: place.geometry.location.lng().toString(),
          image: null,
        },
        onPressSubmit: onPressSubmit,
      });
    } else {
      showMessage({
        message: t('errors.locationNotSelected.title'),
        description: t('errors.locationNotSelected.description'),
        duration: 2000,
      });
    }
  };

  const onPlaceChange = () => {
    const place = searchResult.getPlace();
    clearInterval(locationIntervalRef.current);
    if (place) {
      setMarkedDetail({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        address: place.formatted_address,
      });

      setMapLocation({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };

  const onPressSubmit = (pin: PinPreviewType) => {
    CustomSheetManager.hide('preview-sheet');
    mutate({
      ...pin,
      category_id: pin.category.id,
      sub_category_id: pin.sub_category.id,
      user_id: pin.user?.id,
    });
  };

  const onMarkerChange = async (e: google.maps.MapMouseEvent) => {
    try {
      clearInterval(locationIntervalRef.current);
      setMarker(e.latLng.lat(), e.latLng.lng());
    } catch (error) {
      return;
    }
  };

  const setMarker = async (lat: number, lng: number) => {
    try {
      const params = {
        key: GOOGLE_API_KEY,
        latlng: `${lat},${lng}`,
      };
      const res: AxiosResponse<ReverseGeocodeResponse> = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json`,
        { params },
      );
      inputRef.current.clear();
      inputRef.current['value'] = res.data.results[0].formatted_address;

      setMarkedDetail({
        lat: lat,
        lng: lng,
        address: res.data.results[0].formatted_address,
      });
    } catch (error) {
      return;
    }
  };

  // returns
  return (
    <Container>
      <GoogleMap
        mapContainerStyle={{ flex: 1 }}
        center={mapLocation}
        zoom={20}
        options={{
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          zoomControl: false,
        }}
        onClick={onMarkerChange}>
        <Autocomplete onLoad={setSearchResult} onPlaceChanged={() => onPlaceChange()}>
          <InputContainer>
            <InputStyle
              ref={inputRef}
              icon="search"
              placeHolder="Drag marker or type your address here"
            />
          </InputContainer>
        </Autocomplete>

        <Marker
          position={markedDetail}
          draggable
          onDragEnd={onMarkerChange}
          icon={{
            url: require('@assets/images/marker.png'),
            scaledSize: new google.maps.Size(68 / 1.5, 102 / 1.5),
          }}
        />
      </GoogleMap>

      <GpsIconContainer>
        <CustomIcon
          onPress={onGetCurrentLocation}
          raised
          type="material"
          name="gps-fixed"
          size={34}
        />
      </GpsIconContainer>

      <BottomIconsContainer>
        <CustomPressable onPress={() => navigation.goBack()}>
          <CustomIcon name="x" color="error" reverse raised size={34} isLoading={isLoading} />
        </CustomPressable>

        <CustomPressable onPress={onPressCheck}>
          <CustomIcon name="check" reverse raised size={34} isLoading={isLoading} />
        </CustomPressable>
      </BottomIconsContainer>
    </Container>
  );
};

const Container = styled.View({
  flex: 1,
});

const BottomIconsContainer = styled.View(({ theme: { layout } }) => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'absolute',
  bottom: layout.padding_x4,
  paddingHorizontal: layout.padding_x3,
  width: '100%',
  flexDirection: 'row',
  zIndex: 1,
}));

const InputContainer = styled.View(({ theme: { layout } }) => ({
  position: 'absolute',
  zIndex: 2,
  width: layout.window.width - layout.padding_x3 * 2,
  left: 0,
  right: 0,
  top: layout.padding_x3,
  margin: 'auto',
  fontFamily: fonts.family.bodyRegular,
}));

const GpsIconContainer = styled.View(({ theme: { layout } }) => ({
  position: 'absolute',
  right: layout.padding_x3,
  top: 120,
}));
