// libraries
import React from 'react';
import { GoogleMap } from '@react-google-maps/api';
import styled from 'styled-components/native';

// components
import { CustomMarker, FullScreenLoader } from '@components/molecules';
import { BackBtn } from '@components/atoms';

// misc
import { CustomSheetManager } from '@components/organisms/sheets/utils';
import { useCurrentLocation } from '@hooks/useCurrentLocation';
import { CompositeNavigationProp, RouteProp } from '@react-navigation/native';
import { ProfileStackParamList, RootStackParamList } from '@utils/navigation';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useGetUser } from '@api/useGetUser';

export type UserPinsScreenProp = {
  navigation: CompositeNavigationProp<
    NativeStackNavigationProp<RootStackParamList, 'profile'>,
    NativeStackNavigationProp<ProfileStackParamList, 'userPins'>
  >;
  route: RouteProp<ProfileStackParamList, 'userPins'>;
};

export const UserPinsScreen: React.FC<UserPinsScreenProp> = ({ route }) => {
  // variables
  const { location, isLoading: isCurrentLocationLoading } = useCurrentLocation();
  const { id } = route.params;
  const { data, isLoading } = useGetUser(id);

  // returns
  if (isCurrentLocationLoading || isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Container>
      <BackContainer>
        <BackBtn />
      </BackContainer>

      <GoogleMap
        mapContainerStyle={{ flex: 1 }}
        center={location}
        zoom={10}
        options={{
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          zoomControl: false,
        }}>
        {data?.pins?.map(pin => (
          <CustomMarker
            key={pin.id}
            position={{ lat: parseFloat(pin.latitude), lng: parseFloat(pin.longitude) }}
            iconUrl={pin.category.image_url}
            onPress={() => CustomSheetManager.show('preview-sheet', { pin })}
          />
        ))}
      </GoogleMap>
    </Container>
  );
};

const Container = styled.View({
  flex: 1,
});

const BackContainer = styled.View(({ theme: { layout } }) => ({
  position: 'absolute',
  left: 0,
  top: layout.padding_x1_5,
  zIndex: 2,
}));
