// libraries
import { useMutation } from 'react-query';

// misc
import { useErrorHandler } from '@hooks/useErrorHandler';
import request from '@utils/request';
import { PinType, ServerResponse } from './types';

export type SavePinResponse = Omit<PinType, 'category' | 'sub_category' | 'user'>;

export interface SavePinRequest {
  category_id: number;
  sub_category_id: number;
  user_id: number;
  address: string;
  latitude: string;
  longitude: string;
}

export const useSavePin = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: SavePinResponse) => void;
  onError?: (res: ServerResponse<unknown, SavePinRequest>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();

  // request
  const query = useMutation<
    SavePinResponse,
    ServerResponse<unknown, SavePinRequest> | undefined,
    SavePinRequest
  >(
    [`savePin`],
    async (requestData: SavePinRequest) => {
      const data = await request<ServerResponse<SavePinResponse, SavePinRequest>>({
        method: 'post',
        url: `/pins`,
        data: requestData,
      });

      return data.data;
    },
    {
      onError: error => {
        triggerError({ error });
        onError && onError(error);
      },
      onSuccess: onSuccess,
    },
  );

  // return
  return query;
};
