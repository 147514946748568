// libraries
import React from 'react';
import styled from 'styled-components/native';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

// components
import {
  BigButton,
  CustomPressable,
  CustomText,
  Input,
  SpacerColumn,
  Logo,
} from '@components/atoms';

// styles
import { genericStyles } from '@styles/genericStyles';

// misc
import { useAppNavigation } from '@utils/navigation';
import { SignUpFormType } from './types';
import { useCreateUser } from '@api/useCreateUser';
import { useFormError } from '@hooks/useFormError';
import { TermsAndConditionCheckbox } from './components/TermsAndConditionCheckbox';

export const SignUpScreen = () => {
  // variables
  const navigation = useAppNavigation();
  const { control, handleSubmit, reset, setError } = useForm<SignUpFormType>({
    mode: 'all',
  });
  const { t } = useTranslation();

  const { mutate, isLoading, error } = useCreateUser({
    onSuccess: () => {
      showMessage({
        message: t('success.signup.title'),
        description: t('success.signup.description'),
        type: 'success',
        duration: 2000,
      });
      navigation.navigate('drawer', { screen: 'home' });
      reset();
    },
  });
  useFormError<SignUpFormType>(error, setError, reset);

  // functions
  const onPressSubmit = (data: SignUpFormType) => {
    mutate(data);
  };

  // returns
  return (
    <Container>
      <Content>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <CustomText size={36} font="heading" textAlign="center">
          {t('common.signUp')}
        </CustomText>
        <SpacerColumn size={5} />

        <Input<SignUpFormType>
          control={control}
          name="email"
          placeHolder={t('screens.signUp.email')}
        />
        <SpacerColumn size={1} />

        <TermsAndConditionCheckbox control={control} />
        <SpacerColumn size={4} />

        <BigButton
          text={t('common.submit')}
          onPress={handleSubmit(onPressSubmit)}
          isLoading={isLoading}
        />
      </Content>

      <CustomPressable
        style={genericStyles.rowJcCenter}
        onPress={() => navigation.navigate('auth', { screen: 'signIn' })}>
        <CustomText font="bodyRegular">{t('screens.signUp.alreadyHaveAc')} </CustomText>
        <CustomText font="bodyRegular" color="primary">
          {t('screens.signIn.title')}
        </CustomText>
      </CustomPressable>
    </Container>
  );
};

const Container = styled.View(({ theme: { colors, layout } }) => ({
  backgroundColor: colors.primaryBackground,
  padding: layout.contentPadding,
  flex: 1,
  justifyContent: 'space-between',
}));
const LogoContainer = styled.View({
  marginTop: 20,
  alignItems: 'center',
  marginBottom: 40,
});
const Content = styled.View({});
