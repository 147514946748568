// libraries
import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';

// components
import { HomeScreen } from '@screens/home/HomeScreen.web';
import { AddressSelectionScreen } from '@screens/home/AddressSelectionScreen.web';

// types
import { HomeStackParamList, RouteItem } from '@utils/navigation';
import { fonts } from '@styles/fonts';
import { useFilterRoutes } from '@hooks/useFilterRoutes';
import { useUser } from '@context/UserProvider';

const Stack = createStackNavigator<HomeStackParamList>();

const Items: RouteItem<HomeStackParamList>[] = [
  {
    name: 'pinList',
    component: HomeScreen,
    forRole: 'everyone',
  },
  {
    name: 'addressSelection',
    component: AddressSelectionScreen,
    forRole: 'only-loggedin-user',
  },
];

const HomeStacks = (): ReactElement => {
  // variables
  const { colors } = useTheme();
  const modifyStack = useFilterRoutes(Items);
  const { initLoading } = useUser();
  const { t } = useTranslation();

  // returns
  if (initLoading) {
    return null;
  }

  return (
    <Stack.Navigator
      screenOptions={{
        headerShadowVisible: false,
        headerStyle: {
          backgroundColor: colors.primaryBackground,
        },
        headerTitleStyle: {
          fontFamily: fonts.family.heading,
          fontSize: 28,
        },
        animationEnabled: true,
        headerShown: false,
      }}>
      {modifyStack.map(item => (
        <Stack.Screen
          key={item.name}
          name={item.name}
          component={item.component}
          options={{
            title: t(`navigation.${item.name}`),
          }}
        />
      ))}
    </Stack.Navigator>
  );
};

export default HomeStacks;
