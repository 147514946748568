// libraries
import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components/native';
import { createStackNavigator } from '@react-navigation/stack';

// components
import { ForgotPasswordScreen, SignInScreen, SignUpScreen } from '@screens/authentication';
import { BackBtn, MenuIcon, MenuIconContainer } from '@components/atoms';

// types
import { AuthStackParamList } from '@utils/navigation';
import { fonts } from '@styles/fonts';
import { useUser } from '@context/UserProvider';

const Stack = createStackNavigator<AuthStackParamList>();

const AuthStacks = (): ReactElement => {
  // variables
  const { colors, layout } = useTheme();
  const { initLoading } = useUser();

  // returns
  if (initLoading) {
    return null;
  }

  return (
    <Stack.Navigator
      screenOptions={{
        headerShadowVisible: false,
        headerStyle: {
          backgroundColor: colors.primaryBackground,
        },
        headerTitleStyle: {
          fontFamily: fonts.family.heading,
          fontSize: 28,
        },
        animationEnabled: true,
      }}>
      <Stack.Screen
        name="signIn"
        options={{ headerTitle: '', headerLeft: () => <BackBtn /> }}
        component={SignInScreen}
      />
      <Stack.Screen
        name="signUp"
        options={{ headerTitle: '', headerLeft: () => <BackBtn /> }}
        component={SignUpScreen}
      />
      <Stack.Screen
        name="forgotPassword"
        options={{ headerTitle: '', headerLeft: () => <BackBtn /> }}
        component={ForgotPasswordScreen}
      />
    </Stack.Navigator>
  );
};

export default AuthStacks;
