// libraries
import React, { useMemo, useState } from 'react';
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerContentComponentProps,
} from '@react-navigation/drawer';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components/native';
import { Linking, Platform } from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';
import { showMessage } from 'react-native-flash-message';
import { ShareDialog } from '@components/share/shareDialog';

// components
import {
  Avatar,
  CustomIcon,
  CustomPressable,
  CustomText,
  IconNames,
  MenuIcon,
  MenuIconContainer,
  SpacerColumn,
  SpacerRow,
} from '@components/atoms';
import { CustomSheetManager } from '@components/organisms/sheets/utils';
import { ChangePasswordScreen } from '@screens/settings';
import HomeStacks from './stacks/HomeStacks';
import { OnboardingScreen } from '@screens/onboarding/OnboardingScreen';

// misc
import { DrawerParamList, RouteItem } from '@utils/navigation';
import { genericStyles } from '@styles/genericStyles';
import { generateInitialImage } from '@utils/image';
import { fonts } from '@styles/fonts';
import { useUser } from '@context/UserProvider';
import { getFullName } from '@utils/text';
import { layout } from '@styles/layout';
import { avatarData } from '@utils/constatnt';

// types
interface DrawerItem extends RouteItem<DrawerParamList> {
  icon?: IconNames;
  headerLeft?: () => React.ReactNode;
  hideLabel?: boolean;
  onPress?: () => void;
}

const DrawerItems: DrawerItem[] = [
  {
    name: 'home',
    icon: 'home',
    component: HomeStacks,
    header: () => null,
    forRole: 'everyone',
  },
  {
    name: 'changePassword',
    icon: 'lock',
    component: ChangePasswordScreen,
    forRole: 'only-loggedin-user',
  },
  {
    name: 'onboarding',
    icon: 'sliders',
    component: OnboardingScreen,
    header: () => null,
    forRole: 'everyone',
  },
];

const Drawer = createDrawerNavigator<DrawerParamList>();

const CustomDrawerContent = (props: DrawerContentComponentProps) => {
  // variables
  const { t } = useTranslation();
  const { user, resetAuth } = useUser();
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [shareToken, setShareToken] = useState('');

  const modifyDrawer = useMemo(() => {
    const copyDrawer = [...DrawerItems];

    if (user) {
      copyDrawer.splice(1, 0, {
        onPress: () => CustomSheetManager.show('profile-sheet', { user }),
        name: 'myProfile',
        icon: 'user',
        component: () => null,
        forRole: 'only-loggedin-user',
      });
    } else {
      copyDrawer.splice(1, 0, {
        onPress: () => props.navigation.navigate('auth'),
        name: 'authDrawer',
        icon: 'user',
        component: () => null,
        forRole: 'only-guest',
      });
    }

    copyDrawer.push({
      onPress: () => onShareApp(),
      name: 'shareApp',
      icon: 'share-2',
      component: () => null,
      forRole: 'everyone',
    });

    return copyDrawer
      .filter(({ forRole }) => (user ? forRole != 'only-guest' : forRole != 'only-loggedin-user'))
      .filter(v => !v.hideLabel);
  }, [user]);

  // functions
  const onShareApp = () => {
    if (Platform.OS === 'web') {
      props.navigation.closeDrawer();
      Clipboard.setString(window?.location?.href);
      setShareToken(window?.location?.href);
      setOpenShareDialog(true);
      showMessage({ message: t('success.linkCopied'), type: 'success' });
    }
  };

  // set profile image
  const generateProfileImage = () => {
    if (user) {
      if (user.image !== null && user.image !== '0') {
        return avatarData[user.image].icon;
      }
    }
    return generateInitialImage(getFullName(user));
  };

  // returns
  return (
    <DrawerContentScrollViewStyled {...props} contentContainerStyle={genericStyles.fill}>
      <CustomPressable onPress={() => props.navigation.closeDrawer()} style={{ width: 30 }}>
        <MenuIcon color="white" />
      </CustomPressable>
      <SpacerColumn size={2} />

      <Avatar uri={generateProfileImage()} size="big" />
      <SpacerColumn size={1.5} />
      <CustomText font="bodyBold" size={20} color="white">
        {getFullName(user)}
      </CustomText>

      <SpacerColumn size={4} />

      {modifyDrawer.map(item => (
        <LabelWithIcon
          key={item.name}
          icon={item.icon}
          label={t<string>(`navigation.${item.name}`)}
          onPress={
            item.onPress ||
            (() => {
              props.navigation.navigate(item.name);
              props.navigation.closeDrawer();
            })
          }
        />
      ))}

      <LabelWithIcon
        icon="book-open"
        label={t<string>(`common.privacyPolicy`)}
        onPress={() => Linking.openURL('https://www.crowlr.com/en/privacy-policy/')}
      />
      <LabelWithIcon
        icon="bookmark"
        label={t<string>(`common.termsAndCondition`)}
        onPress={() => Linking.openURL('https://www.crowlr.com/en/terms-of-service/')}
      />

      {user && (
        <FooterContainer>
          <LabelWithIcon
            icon="log-out"
            label={t<string>(`navigation.logOut`)}
            onPress={() => {
              props.navigation.closeDrawer();
              resetAuth();
            }}
          />
        </FooterContainer>
      )}
      {shareToken && (
        <ShareDialog
          open={openShareDialog}
          onClose={() => setOpenShareDialog(false)}
          shareToken={shareToken}
        />
      )}
    </DrawerContentScrollViewStyled>
  );
};

const DrawerNavigator: React.FC = () => {
  // variables
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { token } = useUser();
  const modifyDrawer = useMemo(
    () =>
      DrawerItems.filter(({ forRole }) =>
        token ? forRole != 'only-guest' : forRole != 'only-loggedin-user',
      ),
    [token],
  );

  // returns
  return (
    <Drawer.Navigator
      screenOptions={() => ({
        overlayColor: colors.transparent,
        swipeEnabled: false,
        headerShown: true,
        drawerStyle: { width: 250 },
        headerShadowVisible: false,
        headerStyle: {
          elevation: 0,
          backgroundColor: colors.primaryBackground,
        },
        headerTitleStyle: {
          fontFamily: fonts.family.heading,
          fontSize: 28,
        },
        headerTitleAlign: 'center',
      })}
      drawerContent={CustomDrawerContent}>
      {modifyDrawer.map(item => (
        <Drawer.Screen
          key={item.name}
          name={item.name}
          component={item.component}
          options={({ navigation }) => ({
            header: item.header,
            headerLeft:
              item.headerLeft ||
              (() => (
                <MenuIconContainer
                  onPress={() => navigation.openDrawer()}
                  style={{ marginLeft: layout.padding_x3 }}
                  mapBorder>
                  <MenuIcon />
                </MenuIconContainer>
              )),
            title: item.title || t<string>(`navigation.${item.name}`),
          })}
        />
      ))}
    </Drawer.Navigator>
  );
};

interface LabelWithIconProps {
  icon: IconNames;
  label: string;
  onPress: () => void;
}

const LabelWithIcon = ({ icon, label, onPress }: LabelWithIconProps) => (
  <LabelContainer onPress={onPress}>
    <CustomIcon name={icon} color="white" />
    <SpacerRow size={2} />
    <CustomText size={16} color="white" font="bodyMedium">
      {label}
    </CustomText>
  </LabelContainer>
);

export default DrawerNavigator;

const DrawerContentScrollViewStyled = styled(DrawerContentScrollView)(({ theme: { colors } }) => ({
  padding: layout.padding_x3,
  paddingTop: layout.padding_x1_5,
  background: colors.primary,
}));

const LabelContainer = styled.Pressable(() => ({
  ...genericStyles.rowWithCenter,
  marginBottom: layout.padding_x2_5,
}));

const FooterContainer = styled.View({
  // flex: 1,
  // justifyContent: 'flex-end',
});
