// libraries
import React, { useMemo, useRef } from 'react';
import styled from 'styled-components/native';
import ActionSheet, { ActionSheetRef } from 'react-native-actions-sheet';
import { FieldValues, Path, PathValue, UseFormSetValue, useWatch } from 'react-hook-form';
import { Pressable, StyleSheet, View, ViewStyle } from 'react-native';

// components
import { CustomText, Input, InputProps, RadioButton, SpacerColumn } from '@components/atoms';

// styles
import { layout } from '@styles/layout';
import { CustomActionSheet } from './CustomActionSheet';

export interface SelectInputProps<T extends FieldValues> extends InputProps<T> {
  data: { label: string; value: PathValue<T, Path<T>> }[];
  onChangeValue: UseFormSetValue<T>;
  sheetTitle: string;
  containerStyle?: ViewStyle;
}

export const SelectInput = <T extends FieldValues>({
  data,
  onChangeValue,
  sheetTitle,
  ...restProps
}: SelectInputProps<T>) => {
  // variables
  const actionSheetRef = useRef<ActionSheetRef>(null);
  const value = useWatch({ control: restProps.control, name: restProps.name });
  const displayValue = useMemo(() => data.find(d => d.value === value)?.label || '', [value, data]);

  // returns
  return (
    <Container onPress={() => actionSheetRef.current.show()}>
      <Input editable={false} displayValue={displayValue} {...restProps} />

      <CustomActionSheet ref={actionSheetRef}>
        <View
          style={{
            padding: layout.padding_x3,
          }}>
          <CustomText size={20} font="bodyMedium">
            {sheetTitle}
          </CustomText>
          <SpacerColumn size={3} />
          {data.map(item => (
            <View
              key={item.value}
              style={{
                paddingBottom: layout.padding_x2,
              }}>
              <RadioButton
                onPress={() => {
                  onChangeValue(restProps.name, item.value);
                  actionSheetRef.current.hide();
                }}
                label={item.label}
                value={item.value === value}
              />
            </View>
          ))}
        </View>
      </CustomActionSheet>
    </Container>
  );
};

const Container = styled.Pressable({
  width: '100%',
});
