/* eslint-disable @typescript-eslint/no-empty-function */
// libraries
import React, { createContext, useContext, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

// misc
import { ServerResponse, User } from '@api/types';
import { APP_AUTH_TOKEN, APP_LANGUAGE_KEY, APP_USER_ID } from '@utils/keys';
import request from '@utils/request';
import { useTranslation } from 'react-i18next';

// types
interface DefaultValue {
  user?: User | undefined;
  setAuthUser: (user: User, token: string) => Promise<boolean>;
  resetAuth: () => void;
  getAuthUser: (user?: { id: number; token: string } | undefined) => Promise<boolean>;
  token: string;
  isLoading: boolean;
  initLoading: boolean;
  isUnauthorized: boolean;
}
const defaultValue: DefaultValue = {
  setAuthUser: () => Promise.resolve(true),
  token: '',
  resetAuth: () => {},
  isLoading: false,
  getAuthUser: () => Promise.resolve(true),
  isUnauthorized: false,
  initLoading: true,
};

// context
const UserContext = createContext(defaultValue);

export const UserProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  // user variable
  const [user, setUser] = useState<User>();
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const { i18n } = useTranslation();

  // functions
  const getAuthUser: DefaultValue['getAuthUser'] = async (user = undefined) => {
    try {
      const token = user?.token || (await AsyncStorage.getItem(APP_AUTH_TOKEN));

      if (token) {
        setIsLoading(true);
        const userId = user?.id || (await AsyncStorage.getItem(APP_USER_ID));
        const data = await request<ServerResponse<User>>({
          method: 'get',
          url: `/users/${userId}`,
        });
        return setAuthUser(data.data, token);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }

    setInitLoading(false);
  };

  const setAuthUser: DefaultValue['setAuthUser'] = async (user, token) => {
    await i18n.changeLanguage(user.language);
    setUser(user);
    setToken(token);
    await AsyncStorage.setItem(APP_AUTH_TOKEN, token);
    await AsyncStorage.setItem(APP_USER_ID, user?.id.toString());
    await AsyncStorage.setItem(APP_LANGUAGE_KEY, user.language);
    setIsLoading(false);
    setInitLoading(false);
    return Promise.resolve(true);
  };

  const resetAuth = async () => {
    setUser(undefined);
    setToken('');
    await AsyncStorage.removeItem(APP_AUTH_TOKEN);
    await AsyncStorage.removeItem(APP_USER_ID);
  };

  // user provider
  return (
    <UserContext.Provider
      value={{
        user,
        setAuthUser,
        token,
        resetAuth,
        getAuthUser,
        isLoading,
        isUnauthorized: !user,
        initLoading,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
