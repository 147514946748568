// libraries
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionSheetRef, SheetProps } from 'react-native-actions-sheet';
import { LinearGradient } from 'expo-linear-gradient';
import { FlatList } from 'react-native';

// components
import { SpacerColumn } from '@components/atoms';
import { CustomActionSheet } from '@components/molecules';
import { CategoryItem } from './CategoryItem';

// styles
import { layout } from '@styles/layout';

// misc
import { CategoryType } from '@api/types';

export interface CategorySelectionSheetProps {
  categories: Pick<CategoryType, 'id' | 'name' | 'image_url' | 'status'>[];
  onPress: (categoryId: number) => void;
  isSubCategory?: boolean;
}

const CategorySelectionSheet: React.FC<SheetProps<CategorySelectionSheetProps>> = ({
  sheetId,
  payload,
}) => {
  // variables
  const { t } = useTranslation();
  const actionSheetRef = useRef<ActionSheetRef>(null);

  // returns
  return (
    <CustomActionSheet
      ref={actionSheetRef}
      id={sheetId}
      title={
        payload?.isSubCategory
          ? t('sheets.categorySelection.selectSubCategory')
          : t('sheets.categorySelection.selectMainCategory')
      }
      titleStyle={{
        paddingHorizontal: layout.padding_x3,
      }}
      containerStyle={{ padding: 0 }}>
      <FlatList
        data={payload.categories}
        renderItem={({ item }) =>
          payload?.isSubCategory ? (
            <LinearGradient
              // Background Linear Gradient
              colors={['purple', 'red', 'orange']}
              start={{ x: 0.3, y: 0 }}
              end={{ x: 0.7, y: 1 }}>
              <CategoryItem
                uri={item.image_url}
                description={item.name}
                isSubCategory={payload?.isSubCategory}
                onPress={() => payload.onPress(item.id)}
              />
            </LinearGradient>
          ) : (
            <CategoryItem
              uri={item.image_url}
              description={item.name}
              isSubCategory={payload?.isSubCategory}
              onPress={() => payload.onPress(item.id)}
            />
          )
        }
        keyExtractor={item => item.id.toString()}
        ItemSeparatorComponent={() => <SpacerColumn size={2} />}
        ListHeaderComponent={() => <SpacerColumn size={1} />}
        contentContainerStyle={{ padding: layout.padding_x3, paddingTop: 0 }}
      />
    </CustomActionSheet>
  );
};

export default CategorySelectionSheet;
