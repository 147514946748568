// libraries
import { useQuery } from 'react-query';

// misc
import { useErrorHandler } from '@hooks/useErrorHandler';
import request from '@utils/request';
import { CategoryType } from './types';

interface GetCategoryListResponse {
  data: CategoryType[];
}

export const useGetCategoryList = () => {
  // variables
  const { triggerError } = useErrorHandler();

  // request
  const query = useQuery<GetCategoryListResponse['data']>(
    ['categories'],
    async () => {
      try {
        const data = await request<GetCategoryListResponse>({ method: 'get', url: '/categories' });
        const categoryData = data.data.filter(item => {
          return item.status !== 'inactive';
        });
        return categoryData;
      } catch (error) {
        triggerError(error);
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  // return
  return query;
};
