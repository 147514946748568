// libraries
import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';

// components
import { FollowersScreen, FollowingScreen } from '@screens/social';
import { EditProfileScreen } from '@screens/settings';
import { useUser } from '@context/UserProvider';
import { BackBtn } from '@components/atoms';
import { UserPinsScreen } from '@screens/social/UserPinsScreen';
import { ProfileScreen } from '@screens/social/ProfileScreen';

// types
import { ProfileStackParamList, RouteItem } from '@utils/navigation';
import { fonts } from '@styles/fonts';
import { useFilterRoutes } from '@hooks/useFilterRoutes';

const Items: RouteItem<ProfileStackParamList>[] = [
  {
    name: 'editProfile',
    component: EditProfileScreen,
    forRole: 'only-loggedin-user',
  },
  {
    name: 'followers',
    component: FollowersScreen,
    forRole: 'everyone',
  },
  {
    name: 'following',
    component: FollowingScreen,
    forRole: 'everyone',
  },
  {
    name: 'userPins',
    component: UserPinsScreen,
    forRole: 'everyone',
    header: () => null,
  },
  {
    name: 'profile',
    component: ProfileScreen,
    forRole: 'everyone',
  },
];

const Stack = createStackNavigator<ProfileStackParamList>();

const ProfileStacks = (): ReactElement => {
  // variables
  const { colors } = useTheme();
  const { initLoading } = useUser();
  const modifyStack = useFilterRoutes(Items);
  const { t } = useTranslation();

  // returns
  if (initLoading) {
    return null;
  }

  return (
    <Stack.Navigator
      screenOptions={{
        headerShadowVisible: false,
        headerStyle: {
          backgroundColor: colors.primaryBackground,
        },
        headerTitleStyle: {
          fontFamily: fonts.family.heading,
          fontSize: 28,
        },
        headerTitleAlign: 'center',
        animationEnabled: true,
        headerLeft: () => <BackBtn />,
      }}>
      {modifyStack.map(item => (
        <Stack.Screen
          key={item.name}
          name={item.name}
          component={item.component}
          options={{ title: t('navigation.' + item.name), header: item.header }}
        />
      ))}
    </Stack.Navigator>
  );
};

export default ProfileStacks;
