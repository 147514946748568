// libraries
import React from 'react';
import styled from 'styled-components/native';
import { DrawerActions } from '@react-navigation/native';

// components
import { CustomIcon, MenuIcon } from '@components/atoms';
import { MenuIconContainer } from '@components/atoms';

// styles
import { genericStyles } from '@styles/genericStyles';

// misc
import { useAppNavigation } from '@utils/navigation';

type MapHeaderProps = {
  onPressFilter: () => void;
};

export const MapHeader = ({ onPressFilter }: MapHeaderProps) => {
  // variables
  const navigation = useAppNavigation();

  // returns
  return (
    <Container>
      <MenuIconContainer
        mapBorder
        onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
        color="primary">
        <MenuIcon color="white" />
      </MenuIconContainer>

      <MenuIconContainer mapBorder onPress={onPressFilter} color="primary">
        <CustomIcon name="filter" color="white" size={25} />
      </MenuIconContainer>
    </Container>
  );
};

const Container = styled.View(({ theme: { layout } }) => ({
  ...genericStyles.rowWithCenter,
  width: '100%',
  padding: layout.padding_x3,
  paddingTop: layout.padding_x1_5,
  justifyContent: 'space-between',
  position: 'absolute',
  top: 0,
  zIndex: 2,
}));
