// libraries
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Swiper from 'react-native-web-swiper';

// components
import OnboardingItem from './components/OnboardingItem';
import { CustomPressable, CustomText } from '@components/atoms';

// styles
import { fonts } from '@styles/fonts';

// misc
import { APP_ONBOARDING_KEY } from '@utils/keys';
import { useAppNavigation } from '@utils/navigation';

const DATA = [
  {
    id: 1,
    title: 'Screen One',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    icon: require('@assets/images/onboarding/1.png'),
  },
  {
    id: 2,
    title: 'Screen One',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    icon: require('@assets/images/onboarding/2.png'),
  },
  {
    id: 3,
    title: 'Screen One',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    icon: require('@assets/images/onboarding/3.png'),
  },
  {
    id: 4,
    title: 'Screen Three',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    icon: require('@assets/images/onboarding/4.png'),
  },
];

export const OnboardingScreen = () => {
  // variables
  const { t } = useTranslation();
  const navigation = useAppNavigation();
  const swiperRef = useRef<Swiper>(null);

  // functions
  const onDoneOrSkip = () => {
    swiperRef.current.goTo(0);
    localStorage.setItem(APP_ONBOARDING_KEY, 'true');
    navigation.navigate('drawer', { screen: 'home' });
  };

  // renders
  return (
    <>
      <Swiper
        ref={swiperRef}
        controlsProps={{
          nextTitle: t('common.next'),
          prevTitle: t('common.prev'),
          nextTitleStyle: {
            fontFamily: fonts.family.heading,
            fontWeight: 'bold',
            fontSize: 22,
            color: 'darkgray',
          },
          prevTitleStyle: {
            fontFamily: fonts.family.heading,
            fontWeight: 'bold',
            fontSize: 22,
            color: 'darkgray',
          },
          lastNextElement: (
            <CustomPressable onPress={onDoneOrSkip} pressableStyle={{ zIndex: 1000 }}>
              <CustomText color="gray" font="heading" style={{ fontSize: 22, fontWeight: 'bold' }}>
                {t('common.done')}
              </CustomText>
            </CustomPressable>
          ),
        }}>
        {DATA.map(item => (
          <OnboardingItem
            key={item.id}
            icon={item.icon}
            description={item.description}
            title={item.title}
          />
        ))}
      </Swiper>
    </>
  );
};
