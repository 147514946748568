// libraries
import React, { ReactElement, useLayoutEffect } from 'react';
import { useTheme } from 'styled-components/native';
import { createStackNavigator } from '@react-navigation/stack';
import { LoadScriptProps, useJsApiLoader } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { GOOGLE_API_KEY } from '@env';

// components
import DrawerNavigator from './DrawerNavigator';
import AuthStacks from './stacks/AuthStacks';
import ProfileStacks from './stacks/ProfileStacks';

// types
import { RootStackParamList, RouteItem } from '@utils/navigation';
import { fonts } from '@styles/fonts';
import { useUser } from '@context/UserProvider';
import { useFilterRoutes } from '@hooks/useFilterRoutes';
import { useGetCategoryList } from '@api/useGetCategoryList';
import { SplashScreen } from '@screens/authentication';
import { useCurrentLocation } from '@hooks/useCurrentLocation';

const RootItems: RouteItem<RootStackParamList>[] = [
  {
    name: 'drawer',
    component: DrawerNavigator,
    forRole: 'everyone',
  },
  {
    name: 'auth',
    component: AuthStacks,
    forRole: 'only-guest',
  },
  {
    name: 'profile',
    component: ProfileStacks,
    forRole: 'everyone',
  },
];

const Stack = createStackNavigator<RootStackParamList>();

const LIBRARIES: LoadScriptProps['libraries'] = ['places'];

const RootNavigator = (): ReactElement => {
  // variables
  const { colors } = useTheme();
  const { getAuthUser, initLoading } = useUser();
  const { isLoading: isCurrentLocationLoading } = useCurrentLocation();
  const modifyStack = useFilterRoutes(RootItems);
  useGetCategoryList();
  const { t } = useTranslation();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: LIBRARIES,
  });

  // hooks
  useLayoutEffect(() => {
    getAuthUser();
  }, []);

  // returns
  if (initLoading || !isLoaded || isCurrentLocationLoading) {
    return <SplashScreen />;
  }

  return (
    <Stack.Navigator
      screenOptions={{
        headerShadowVisible: false,
        headerStyle: {
          backgroundColor: colors.primaryBackground,
        },
        headerTitleStyle: {
          fontFamily: fonts.family.heading,
          fontSize: 28,
        },
        animationEnabled: true,
        headerShown: false,
      }}>
      {modifyStack.map(item => (
        <Stack.Screen
          key={item.name}
          name={item.name}
          component={item.component}
          options={{
            title: t(`navigation.${item.name}`),
          }}
        />
      ))}
    </Stack.Navigator>
  );
};

export default RootNavigator;
