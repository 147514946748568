// libraries
import i18n, { LanguageDetectorAsyncModule } from 'i18next';
import { initReactI18next } from 'react-i18next';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as RNLocalize from 'react-native-localize';

// translations
import English from '@localization/locales/English.json';
import French from '@localization/locales/French.json';
import { APP_LANGUAGE_KEY } from '@utils/keys';

export const resources = {
  English: { translation: English },
  French: { translation: French },
} as const;

export type Language = keyof typeof resources;
export const defaultNS: Language = 'English';

const LANGUAGE_DETECTOR: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  detect: (callback: (lang: string) => void) => {
    AsyncStorage.getItem(
      APP_LANGUAGE_KEY,
      (err: Error | undefined, language: string | undefined) => {
        if (err || !language) {
          const findBestAvailableLanguage = RNLocalize.findBestAvailableLanguage(
            Object.keys(resources),
          );
          callback(findBestAvailableLanguage?.languageTag || defaultNS);
          return;
        }
        callback(language);
      },
    );
  },
  init: () => null,
  cacheUserLanguage: (language: Language) => {
    AsyncStorage.setItem(APP_LANGUAGE_KEY, language);
  },
};

i18n
  // detect language
  .use(LANGUAGE_DETECTOR)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // set options
  .init({
    resources,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    fallbackLng: defaultNS,
  });

export default i18n;
