/* eslint-disable react/display-name */

// libraries
import React, { forwardRef } from 'react';
import ActionSheet, { ActionSheetProps, ActionSheetRef } from 'react-native-actions-sheet';
import { StyleSheet, TextStyle, TouchableOpacity, Image } from 'react-native';

// components
import { CustomText, SpacerColumn } from '@components/atoms';

// styles
import { layout } from '@styles/layout';
import { CustomSheetManager } from '@components/organisms/sheets/utils';

interface CustomActionSheetProps extends ActionSheetProps {
  title?: string;
  titleStyle?: TextStyle;
}

export const CustomActionSheet = forwardRef<ActionSheetRef, CustomActionSheetProps>(
  ({ containerStyle, title, titleStyle, children, ...restProps }, ref) => {
    // returns
    return (
      <ActionSheet
        ref={ref}
        containerStyle={{ ...styles.sheetContainer, ...(containerStyle || {}) }}
        indicatorStyle={{ marginVertical: layout.padding_x2 }}
        gestureEnabled={true}
        {...restProps}>
        {title && (
          <>
            <CustomText size={20} font="bodyMedium" style={titleStyle}>
              {title}
            </CustomText>
            <TouchableOpacity
              style={{
                position: 'absolute',
                right: 18,
                top: 18,
              }}
              onPress={() => {
                CustomSheetManager.hideAll();
              }}>
              <Image
                source={require('../../assets/images/close.png')}
                style={{ height: '20px', width: '20px', padding: '10px' }}
              />
            </TouchableOpacity>
            <SpacerColumn size={3} />
          </>
        )}
        {children}
      </ActionSheet>
    );
  },
);

const styles = StyleSheet.create({
  sheetContainer: {
    paddingTop: 0,
    borderTopRightRadius: 30,
    borderTopLeftRadius: 30,
  },
});
