// libraries
import { useQuery } from 'react-query';

// misc
import { useErrorHandler } from '@hooks/useErrorHandler';
import request from '@utils/request';
import { ServerResponse, User } from './types';

type GetUserResponse = User;

export const useGetUser = (id: number) => {
  // variables
  const { triggerError } = useErrorHandler();

  // request
  const query = useQuery<GetUserResponse>(
    [`users`, id],
    async () => {
      try {
        if (id) {
          const data = await request<ServerResponse<GetUserResponse>>({
            method: 'get',
            url: `/users/${id}`,
          });

          return data.data;
        }

        return null;
      } catch (error) {
        triggerError(error);
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  // return
  return query;
};
