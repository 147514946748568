// misc
import { layout } from './layout';

/**
 * to add transparency to an hexa color, see this
 * https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
 */

export const theme = {
  colors: {
    // main
    primary: '#3422F2',
    get primary50() {
      return this.primary + 80;
    },
    secondary: '#000',
    primaryBackground: '#F8F8FF',
    secondaryBackground: '#fff',
    success: '#00EF9A',
    error: '#F77373',
    gray: '#808080',
    silver: '#A5A5A5',
    white: '#ffffff',
    alto: '#D1D1D1',
    snuf: '#D6CDE4',
    porcelain: '#F4F5F6',
    mineShaft: '#1F1F1F',

    // other
    text: '#000',
    get text50() {
      return this.text + 80;
    },
    transparent: 'transparent',
  },
  layout: { ...layout },
};
