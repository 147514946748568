import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  FacebookShareCount,
  PinterestShareCount,
  VKShareCount,
  OKShareCount,
  RedditShareCount,
  TumblrShareCount,
  HatenaShareCount,
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  WeiboShareButton,
  PocketShareButton,
  InstapaperShareButton,
  HatenaShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  MailruIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  WeiboIcon,
  HatenaIcon,
} from 'react-share';

import './share.css';

export const ShareDialog = ({ shareToken, open, onClose, ...restProps }) => {
  const handleClose = () => {
    // setOpenShareDialog(false);
    onClose();
  };

  const shareUrl = shareToken;
  const title = 'Voila Profile';

  return (
    <Dialog onClose={handleClose} open={open} style={{ zIndex: 9999 }}>
      <DialogTitle>Share On:</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <List sx={{ pt: 0 }}>
        <div className="share-buttons-container">
          <FacebookShareButton
            url={shareUrl}
            quote={title}
            className="Demo__some-network__share-button">
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </div>
        <div className="share-buttons-container">
          <TwitterShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button">
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </div>
        <div className="share-buttons-container">
          <TelegramShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button">
            <TelegramIcon size={32} round />
          </TelegramShareButton>
        </div>
        <div className="share-buttons-container">
          <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: "
            className="Demo__some-network__share-button">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>
        <div className="share-buttons-container">
          <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>
        <div className="share-buttons-container">
          <TumblrShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button">
            <TumblrIcon size={32} round />
          </TumblrShareButton>
        </div>
        <div className="share-buttons-container">
          <LivejournalShareButton
            url={shareUrl}
            title={title}
            description={shareUrl}
            className="Demo__some-network__share-button">
            <LivejournalIcon size={32} round />
          </LivejournalShareButton>
        </div>
        <div className="share-buttons-container">
          <EmailShareButton
            url={shareUrl}
            subject={title}
            body="body"
            className="Demo__some-network__share-button">
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>
        {/* <div className="share-buttons-container">
          <ViberShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button">
            <ViberIcon size={32} round />
          </ViberShareButton>
        </div> */}
        <div className="share-buttons-container">
          <WorkplaceShareButton
            url={shareUrl}
            quote={title}
            className="Demo__some-network__share-button">
            <WorkplaceIcon size={32} round />
          </WorkplaceShareButton>
        </div>
        {/* <div className="share-buttons-container">
          <LineShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button">
            <LineIcon size={32} round />
          </LineShareButton>
        </div> */}
        <div className="share-buttons-container">
          <PocketShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button">
            <PocketIcon size={32} round />
          </PocketShareButton>
        </div>
        <div className="share-buttons-container">
          <InstapaperShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button">
            <InstapaperIcon size={32} round />
          </InstapaperShareButton>
        </div>
        <div className="share-buttons-container">
          <HatenaShareButton
            url={shareUrl}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className="Demo__some-network__share-button">
            <HatenaIcon size={32} round />
          </HatenaShareButton>
        </div>
      </List>
    </Dialog>
  );
};
