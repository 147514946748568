import React from 'react';
import { View, Image, useWindowDimensions, StyleSheet } from 'react-native';
import { useImageResizer } from '@hooks/useImageResizer';

type TextStyles = {
  fontSize?: number;
  fontFamily?: string;
  color?: string;
  fontWeight?: string | number;
  textAlign?: string;
};

type OnboardingItemProps = {
  icon: any;
  title: string;
  description: string;
  headingStyle?: TextStyles;
  descriptionStyle?: TextStyles;
};

const OnboardingItem = ({ icon, title, description }: OnboardingItemProps) => {
  // variables
  const { width, height } = useWindowDimensions();
  const { width: imageWidth, height: imageHeight } = useImageResizer({
    image: icon,
    maxSize: { width },
  });

  // renders
  return (
    <View style={[styles.container, { width: width }]}>
      <Image style={{ width: imageWidth, height: imageHeight }} source={icon} />
      {/* <CustomText font="bodyBold" size={24}>
        {title}
      </CustomText> */}
      {/* <SpacerColumn size={2} />
      <CustomText font="bodyLight" size={14} style={styles.description}>
        {description}
      </CustomText> */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  swipeItem: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  listWrapper: {
    minHeight: '50%',
    maxHeight: '90%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    height: 280,
    width: 280,
  },
  description: { width: '80%', textAlign: 'center' },
});

export default OnboardingItem;
