/* eslint-disable react/no-unescaped-entities */
// libraries
import React from 'react';
import { Shadow } from 'react-native-shadow-2';
import styled from 'styled-components/native';

// components
import { CustomPressable, CustomText, SpacerRow } from '@components/atoms';

// styles
import { genericStyles } from '@styles/genericStyles';

// types
type CategoryItemProps = {
  uri?: string;
  description?: string;
  isSubCategory?: boolean;
  onPress?(): void;
};

export const CategoryItem = ({ uri, description, isSubCategory, onPress }: CategoryItemProps) => {
  // returns

  return (
    <CustomPressable onPress={onPress}>
      <Container distance={1}>
        {uri && (
          <>
            <Image source={{ uri }} onError={e => console.log('e', e)} />
            <SpacerRow size={1.5} />
          </>
        )}

        <CustomText font="bodyLight" size={16} color={isSubCategory ? 'white' : 'silver'}>
          {description}
        </CustomText>
      </Container>
    </CustomPressable>
  );
};

const Container = styled(Shadow)(({ theme: { layout } }) => ({
  ...genericStyles.rowWithCenter,
  borderRadius: 16,
  paddingVertical: layout.padding_x1_5,
  paddingHorizontal: layout.padding_x3,
  width: '100%',
  height: 74,
}));

const Image = styled.Image({
  width: 50,
  height: 50,
  borderRadius: 50,
});
