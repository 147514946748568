// libraries
import { useQuery } from 'react-query';

// misc
import { useErrorHandler } from '@hooks/useErrorHandler';
import request from '@utils/request';
import { PinType, ServerResponse } from './types';
import { FilterCategory } from '@screens/home/components/FilterModalScreen';

type GetPins = PinType[];

export const useGetPins = (categoryId: FilterCategory['id'][] = []) => {
  // variables
  const { triggerError } = useErrorHandler();
  const url = categoryId === null ? `/pins` : `/pins?category_ids=${categoryId?.join(',')}`;
  // request
  const query = useQuery<GetPins>(
    [`pins`, categoryId],
    async ({ pageParam }) => {
      try {
        const data = await request<ServerResponse<GetPins>>({
          method: 'get',
          url: url,
          params: {
            page: pageParam,
            category_ids: categoryId?.join(',') || null,
            is_follower: categoryId?.includes('follower'),
            is_following: categoryId?.includes('following'),
          },
        });

        return data.data;
      } catch (error) {
        triggerError(error);
      }
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    },
  );

  // return
  return query;
};
