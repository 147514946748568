// libraries
import React, { useRef } from 'react';
import { ActionSheetRef, SheetProps, useScrollHandlers } from 'react-native-actions-sheet';

// components
import { CustomActionSheet } from '@components/molecules';

// misc
import { User } from '@api/types';
import { Profile } from '../Profile';
import { ScrollView } from 'react-native';
import { layout } from '@styles/layout';

export interface ProfileSheetProps {
  user: User;
  onFollowChange?: (isFollowed: boolean) => void;
}

export const ProfileSheet: React.FC<SheetProps<ProfileSheetProps>> = ({ sheetId, payload }) => {
  // variables
  const actionSheetRef = useRef<ActionSheetRef>(null);

  const scrollHandlers = useScrollHandlers<ScrollView>('ProfileSheet-1', actionSheetRef);

  // renders
  return (
    <CustomActionSheet ref={actionSheetRef} id={sheetId}>
      <ScrollView
        {...scrollHandlers}
        contentContainerStyle={{
          padding: layout.padding_x3,
        }}>
        <Profile user={payload.user} onFollowChange={payload.onFollowChange} />
      </ScrollView>
    </CustomActionSheet>
  );
};
