// libraries
import 'react-native-gesture-handler';
import '@localization/i18n';
import '@components/organisms/sheets';
import * as React from 'react';
import { useFonts } from 'expo-font';
import { SheetProvider } from 'react-native-actions-sheet';
import { ThemeProvider } from 'styled-components/native';
import { createNavigationContainerRef, NavigationContainer } from '@react-navigation/native';
import * as serviceWorkerRegistration from './src/pwa/serviceWorkerRegistration';
import FlashMessage from 'react-native-flash-message';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Platform } from 'react-native';
import { ConfirmModalProvider } from '@sj-distributor/react-native-confirm-modal';
import RootNavigator from '@navigation/RootNavigator';

// misc
import { theme } from '@styles/themes';
import { UserProvider } from '@context/UserProvider';
import { linking, RootStackParamList } from '@utils/navigation';

export const navigationRef = createNavigationContainerRef<RootStackParamList>();

const queryClient = new QueryClient();
const App = () => {
  // variables
  const [fontsLoaded] = useFonts({
    Medel: require('./src/assets/fonts/Medel.ttf'),
    'Poppins-Bold': require('./src/assets/fonts/Poppins-Bold.ttf'),
    'Poppins-Italic': require('./src/assets/fonts/Poppins-Italic.ttf'),
    'Poppins-Medium': require('./src/assets/fonts/Poppins-Medium.ttf'),
    'Poppins-Regular': require('./src/assets/fonts/Poppins-Regular.ttf'),
    'Poppins-Light': require('./src/assets/fonts/Poppins-Light.ttf'),
  });

  // returns
  if (!fontsLoaded) {
    return null;
  }

  return (
    <ConfirmModalProvider>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <NavigationContainer ref={navigationRef} linking={linking}>
              <SheetProvider>
                <RootNavigator />

                {/* GLOBAL FLASH MESSAGE COMPONENT INSTANCE */}
                <FlashMessage
                  position="top"
                  statusBarHeight={Platform.OS === 'web' ? 0 : undefined}
                />
              </SheetProvider>
            </NavigationContainer>
          </UserProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </ConfirmModalProvider>
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
export default App;
