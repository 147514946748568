// libraries
import React from 'react';
import { StyleSheet, Image } from 'react-native';

// logo
import logoImg from '@assets/images/logo.png';

export const Logo = () => {
  return <Image source={logoImg} style={styles.image} />;
};

const styles = StyleSheet.create({
  image: {
    width: 100,
    height: 100,
  },
});
